import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Drive APi
import { AppContext } from '../infrastructure/app.context';
import { AppRepository } from '../infrastructure/repositories/app.repository';
import { AppSession } from '../infrastructure/sessions/app.session';
import { BreadCrumbSession } from '../infrastructure/sessions/breadcrumb.session';
import { FileRepository } from '../infrastructure/repositories/file.repository';
import { FileSession } from '../infrastructure/sessions/file.session';
import { GapiSession } from '../infrastructure/sessions/gapi.session';
import { LoggedInGuard } from '../infrastructure/sessions/loggedInGuard';
import { UserRepository } from '../infrastructure/repositories/user.repository';
import { UserSession } from '../infrastructure/sessions/user.session';


import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule } from 'ngx-loading';
// import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
// import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { NgxPaginationModule } from 'ngx-pagination';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxPrintModule } from 'ngx-print';
import { NgxPayPalModule } from 'ngx-paypal';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { MenuComponent } from './shared/menu/menu.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LoginAdminComponent } from './pages/login/login-admin/login-admin.component';
import { LoginUserComponent } from './pages/login/login-user/login-user.component';
import { AdminComponent } from './pages/profile/admin/admin.component';
import { UserComponent } from './pages/profile/user/user.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PromotionsComponent } from './pages/promotions/promotions.component';
import { CommonModule } from '@angular/common';
import { YoutubePipe } from './pipes/youtube.pipe';

//Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
// import { NgxImgZoomModule } from 'ngx-img-zoom';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    FooterComponent,
    LoginAdminComponent,
    LoginUserComponent,
    AdminComponent,
    UserComponent,
    AboutUsComponent,
    PromotionsComponent,
    YoutubePipe
  ],
  imports: [
    FormsModule,
    BrowserModule,
    NgbModalModule,
    AppRoutingModule,
    HttpClientModule,
    // SocialLoginModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgbModule,
    LazyLoadImageModule,
    NgxPrintModule,
    NgxPayPalModule,
    CarouselModule, CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    CommonModule,
    // NgxImgZoomModule
  ],
  providers: [
    // { provide: APP_INITIALIZER, useFactory: initGapi, deps: [GapiSession], multi: true },
    AppContext,
    
    AppSession,
    FileSession,
    GapiSession,
    UserSession,

    AppRepository,
    BreadCrumbSession,
    FileRepository,
    UserRepository,
    
    LoggedInGuard
  ],
  schemas: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
