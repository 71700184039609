
<!-- <section style="position: fixed; width: 100%; z-index: 5;"></section> -->

<section>

  <!-- <div class="container-fluid fixed-top">

    <div class="row bg-dark text-light text-size-12">

      <div class="col-6 pt-2 pb-2">
        <div class="pl-3">
          <span>
            Bienvenidos a <strong>{{companyNane}}</strong> Sitio Web
          </span>
        </div>
      </div>

      <div class="col-6 pt-2 pb-2">

        <div class="pr-3 float-right" *ngIf="!clientLogin.login">

          <div class="float-right" (click)="loginAdmin()">
            <span class="ml-3 spn-user-login" role="button">
              Administrador
              <i class="fas fa-user-cog ml-2"></i>
            </span>
          </div>

          <div class="float-right" (click)="loginUser()">
            <span class="pr-3 linea-right-blanca spn-user-login" role="button">
              <i class="fas fa-user-lock mr-2"></i>
              Iniciar Sesión
            </span>
            |
          </div>

        </div>

        <div class="pr-3 float-right" *ngIf="clientLogin.login">

          <div class="float-right" (click)="signOff()">
            <span class="ml-3 spn-user-login" role="button">
              <i class="fas fa-lock ml-2 text-danger"></i>
            </span>
          </div>

          <div class="float-right" (click)="goClientProfile()">
            <img src="../../../assets/img/user.jpg" class="rounded-circle mr-2" width="25px" height="25px" *ngIf="!clientLogin.imagen">
            <img src="https://drive.google.com/uc?export=view&id={{clientLogin.imagen}}" class="rounded-circle mr-2" width="25px" height="25px" *ngIf="clientLogin.imagen">
            <span class="pr-3 linea-right-blanca spn-user-login" role="button">
              {{clientLogin.name}}
            </span>
            |
          </div>

        </div>
      </div>

    </div>

  </div> -->

  <nav  class="navbar navbar-expand-lg  shadow-sm fixed-top" id="viewLG">
    <div class="container-fluid">
      <a class="navbar-brand" >
        <img  class="imgNavbar" [src]="urlRuta+configuracion?.imgLogo+'?alt=media'" style="margin-left: 0.5em; ">
      </a>
      <button (click)="seeExpand()" class="navbar-toggler" type="button" class="navbar-toggler" type="button"  aria-controls="navbarSupportedContent">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse "   [ngClass]="{'show': !isNavbarCollapsed}" id="navbarSupportedContent1" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto" style="display: flex; justify-content: center; text-align: center; " >
          <li class="nav-item">
            <a  class="nav-link" (click)="goSectionRoom()" [ngStyle]="{ 'color':colorLeter() } "  >Habitaciones</a>
          </li>
          <li class="nav-item">
            <a  class="nav-link"  (click)="goPromotions()" [ngStyle]="{ 'color':colorLeter() } ">Ofertas</a>
          </li>
          <li class="nav-item">
            <a   (click)="goToAboutUs()" [ngStyle]="{ 'color':colorLeter() } "  >Sobre Nosotros</a>
          </li>
       
  
        
        </ul>
  
          <div  >
            <div class=" pt-2 pb-2">

              <div class="pr-3 float-right" *ngIf="!clientLogin.login">
      
                <div class="float-right" (click)="loginAdmin()">
                  <span class="ml-3 spn-user-login" role="button">
                    Administrador
                    <i class="fas fa-user-cog ml-2"></i>
                  </span>
                </div>
      
                <div class="float-right" (click)="loginUser()">
                  <span class="pr-3 linea-right-blanca spn-user-login" role="button">
                    <i class="fas fa-user-lock mr-2"></i>
                    Iniciar Sesión
                  </span>
                  |
                </div>
      
              </div>
      
              <div class="pr-3 float-right" *ngIf="clientLogin.login">
      
                <div class="float-right" (click)="signOff()">
                  <span class="ml-3 spn-user-login" role="button">
                    <i class="fas fa-lock ml-2 text-danger"></i>
                  </span>
                </div>
      
                <div class="float-right" (click)="goClientProfile()">
                  <img src="../../../assets/img/user.jpg" class="rounded-circle mr-2" width="25px" height="25px" *ngIf="!clientLogin.imagen">
                  <img src="https://drive.google.com/uc?export=view&id={{clientLogin.imagen}}" class="rounded-circle mr-2" width="25px" height="25px" *ngIf="clientLogin.imagen">
                  <span class="pr-3 linea-right-blanca spn-user-login" role="button">
                    {{clientLogin.name}}
                  </span>
                  |
                </div>
      
              </div>
            </div>

  
          </div> 
      </div>
    </div>
  </nav> 
  <!-- <nav class="navbar navbar-expand-lg fixed-top" id="navBarC" style="background-color: white; margin-top: 2.3em;">
    <div class="container" >
        <a class="navbar-nav"  >
          <img    [src]="urlRuta+configuracion?.imgLogo+'?alt=media'" style="width: 100%;height: 45px; object-fit: scale-down;">
        </a>
      <button class="navbar-toggler"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
         <i class="bi bi-list" [ngStyle]="{ 'color':colorLeter() } "></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item" >
            <a class="nav-link active" aria-current="page" type="button"  (click)="goSectionRoom()" [ngStyle]="{ 'color':colorLeter() } "   ><i class="bi bi-house-fill"></i> Habitaciones</a>
          </li>
          <li class="nav-item" >
            <a class="nav-link" type="button"  (click)="goPromotions()" [ngStyle]="{ 'color':colorLeter() } " >Ofertas</a>
          </li>

          <li class="nav-item" >
            <a class="nav-link" type="button"  (click)="goToAboutUs()" [ngStyle]="{ 'color':colorLeter() } "  >Sobre Nosotros</a>
          </li>

        </ul>
      </div>
    </div>
  </nav> -->

 
</section>

<!-- Boton chat de whastapp -->
<div class="div-chat-whatsapp">
  <div class="text-right" (click)="contactanosWhatsApp(whatsapp)">
      <i class="fab fa-whatsapp"></i>
      <span class="spn-info-whats text-size-14 pl-2 pr-2 pt-1 pb-1 rounded-pill text-light" role="button">
          Asistencia al cliente
          <i class="fas fa-chalkboard-teacher"></i>
      </span>
  </div>
</div>

<!-- Cantactar por whatsapp-->
<ng-template #whatsapp let-modal class="modal-whatsapp rounded">

  <div class="modal-header header-whatsapp tipo-letra">
      <i class="fab fa-whatsapp icn-whatsapp"></i>
      <p class="modal-title title-whatsapp">Escríbenos en WhatsApp</p>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
          style="color: white;">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>

  <div class="modal-body body-whatsapp tipo-letra">

      <div class="row cuadro-mensaje">
          <div class="col-12">
              <p class="p-mensaje">Hola, en que te podemos ayudar.</p>
          </div>
      </div>

      <div class="row" style="margin-top: 20px;">

          <div class="col-10">
              <input type="text" class="inp-mensaje" placeholder="Escríbe un mensaje" [(ngModel)]="whatsApp.texto">
          </div>

          <div class="col-2 div-send">
              <div class="boton" (click)="modal.close('Close click')">
                  <a>
                      <i class="fas fa-angle-right pt-1"></i>
                  </a>
              </div>
          </div>

      </div>

      <br>

  </div>

</ng-template>