<app-menu></app-menu>

<!-- <ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true }" [template]="">


</ngx-loading> -->
<div class="imgAU" style="padding-top: 7em;">
    <img [src]="urlBase+aboutUs.image+'?alt=media'"  style="width: 100%; " alt="" id="imgAboutUs">
    <div class="carousel-caption " id="">
        <div class="padding:1em">
            <h1    style="color: white; text-shadow: black 0.1em 0.1em 0.2em ;font-weight: bold; font-family: 'Montserrat', sans-serif; font-size: 2.1em; font-style: italic; ">NUESTRA HISTORIA </h1>
            <h1   style="color: white;text-shadow: black 0.1em 0.1em 0.2em; font-weight: bold; font-family: 'Montserrat', sans-serif;font-size: 2em; ">{{companyNane | uppercase}}</h1>
        </div>
      </div>

</div>
<section>
    <div class="container-fluid" style="width: 80%;"  >
        <div class="row divSelect ">
            <div class="col-lg-6 p-4">
                <h6 class="pb-2" [ngStyle]="{'color':colorLeter() }"  style=" text-align: justify ;font-weight: bold; font-family: 'Montserrat', sans-serif;font-size: 1em; ">HISTORIA</h6>            
                <span>{{aboutUs.text}}</span>

            </div>
            <div class="col-lg-6 p-4">
                <!-- <img src="https://drive.google.com/uc?export=view&id={{imagenBanner1}}"  style="width: 100%;"  alt="" id="imgAboutUs1">      -->
                <img [src]="urlBase+ imagenBanner+'?alt=media'"  style="width: 100%;"  alt="" id="imgAboutUs1">     
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container-fluid" style="width: 90%;"  >
        <!-- <div class="row divSelect ">
            <div class="col-lg-12 pt-5">
                <h6 class="pb-2" [ngStyle]="{'color':colorLeter() }"  style=" text-align: center ;font-weight: bold; font-family: 'Montserrat', sans-serif;font-size: 1em; ">SOBRE NOSOTROS</h6>            

            </div>
           
        </div> -->
        <!-- <div class="row divSelect">
            <div class="col">
                <img src="../../../assets/img/iconos-mision-01.png" alt="" style="width: 200px; height: auto;">
            </div>
            <div class="col">
                <img src="../../../assets/img/iconos-vision-01.png" alt="" style="width: 200px; height: auto;">
            </div>
            <div class="col">
                <img src="../../../assets/img/iconos-vision-01.png" alt="" style="width: 200px; height: auto;">
            </div>
        </div> -->
        <div class="row pt-5">

            <h1 class="text-center" style="font-family:'Montserrat' ;" id="sobreNosotrosTitle" [ngStyle]="{'color':colorLeter() }"  >¡CÓMO LO HACEMOS!</h1>
            <p class="text-center" style="font-family: 'Helvetica'; font-size: large;" id="str-history1">
                <strong>
                    {{information.nombre}}
                </strong>
            </p>
            <div class="col-lg-4 col-md-6 pt-3" >
                <div class="w-100 text-center " >
    
                    <img style="width: 25%; height: auto;" src="../../../assets/img/iconos-mision-01.png" alt="">
                  
                    <!-- <img class="rounded" width="75%" *ngIf="information.imagenMision"
                        src="https://drive.google.com/uc?export=view&id={{information.imagenMision}}">
                    <img class="rounded" width="75%" *ngIf="!information.imagenMision"
                        src="https://drive.google.com/uc?export=view&id=14TBXlgCxm5DcOniXoqLkOBQmirjIg6vx"> -->
                </div>
                <br>
                <div class="w-100">
                    <p class="divSelect pMV fw-bold" id="pM" [ngStyle]="{'color':colorLeter() }" >
                        QUÉ HACEMOS
                    </p>
                    <p class="parMV" style="text-align: justify;">
                        {{information.mision}}
                    </p>
                </div>
            </div>
    
            <div class="col-lg-4 col-md-6">
                <div class="w-100 text-center border border-light rounded">
                    <img style="width: 25%; height: auto;" src="../../../assets/img/iconos-vision-01.png" alt="">
                    <!-- <img class="rounded" width="75%" *ngIf="information.imagenVision"
                        src="https://drive.google.com/uc?export=view&id={{information.imagenVision}}">
                    <img class="rounded" width="75%" *ngIf="!information.imagenVision"
                        src="https://drive.google.com/uc?export=view&id=1gyk0MaZzSIsNJRLGwlqjUvmCFumNaZVY"> -->
                </div>
                <br>
                <div class="w-100">
                    <p class="pMV divSelect fw-bold"  id="pV" [ngStyle]="{'color':colorLeter() }" >
                        PARA QUÉ LO HACEMOS
                    </p>
                    <p class="parMV" style="text-align: justify;">
                        {{information.vision}}
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="w-100 text-center border border-light rounded">
                    <img style="width: 25%; height: auto;" src="../../../assets/img/iconos-vision-01.png" alt="">

                </div>
                <br>
                <div class="w-100">
                    <p class="pMV divSelect fw-bold" id="pV" [ngStyle]="{'color':colorLeter() }" >
                        VALORES
                    </p>
                    <p class="parMV" style="text-align: justify;">
                        {{information.valores}}
                    </p>
                </div>
            </div>
            <!-- src="https://drive.google.com/uc?export=view&id={{imagenBanner}}" -->

            <div class="bgHeader" style="margin-bottom: 4em; border-radius: 8px; background-image: linear-gradient(rgba(160, 157, 157, 0.5), rgba(0, 0, 0, 0.5)),url(https://drive.google.com/uc?export=view&id={{imagenBanner}})">
                <div class="" id="headerCEspecializadadLG">
                <div class="row w-100">
        
                    <div class="col-lg-12 col-12 p-5">
                        <div class="text-center pt-4" >
                            <span style="font-size:  1.8rem; z-index: 2; color: white; font-family: 'Helvetica';font-style: italic; " >
                                <!-- <strong>{{companyNane |titlecase}}</strong> -->
                                <strong>''{{companyNane |titlecase}}''</strong>
                           </span>
                        </div>
        
                    </div>
                    <div class="col-lg-12">
        
          
                    <div class="row ">
                        <div class="col ">
                            <h4 class="divSelect" style="font-family: 'Montserrat'; ">Video Promocional</h4>
                            <div class="divSelect">        
                                <iframe  style="border-radius: 8px;" width="560" height="315" [src]="videoY | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div class="col ">
                            <h4 class="divSelect" style="font-family: 'Montserrat'; ">Dirección</h4>
                            <div class="divSelect">
                                <div id="map" class="p-1"
                                style="width: 560px; height: 315px; border-radius: 12px;"  ></div>
                            </div>
                                <!-- <iframe  style="border-radius: 8px;" [src]="information.latitud" width="560" height="315" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                            </div> -->
                        </div>
        
                    </div>
                </div>
        
                </div>
        
        
            </div>
               
                
            </div>


    
            <!-- <div class="col-lg-4">
                <div class="w-100 text-center border border-light rounded">
                    <img class="rounded" width="75%" *ngIf="information.imagenValores" src="https://drive.google.com/uc?export=view&id={{information.imagenValores}}">
                    <img class="rounded" width="75%" *ngIf="!information.imagenValores" src="https://drive.google.com/uc?export=view&id=1O6qHa3fef2bXutBz2TYuHTzyreCw7QcK">
                </div>
                <br>
                <div class="w-100">
                    <p class="text-minuscula-texto-negro text-size-20 text-secondary">
                        Valores
                    </p>
                    <p class="text-minuscula-texto-negro text-justify text-size-14">
                        {{information.valores}}
                    </p>
                </div>
            </div> -->
        </div>
    </div>
</section>



<!-- <section >

    <br>
    <br>
    <br>

    <div class="container">

        <br>

        <div class="row">

            <div class="col-lg-7 rounded-circle bg-light" >


                <p class="text-size-22 text-tituto-general">
                    {{aboutUs.title}}
                </p>


                <p class="text-justify ">
                    {{aboutUs.text}}
                </p>

         

            </div>

            <div class="col-lg-5 text-center">
                <br>
                <img class="rounded" src="https://drive.google.com/uc?export=view&id={{aboutUs.image}}" *ngIf="aboutUs.image" alt="" style="height: 300px;" >
                <img class="rounded" src="https://drive.google.com/uc?export=view&id=1O6qHa3fef2bXutBz2TYuHTzyreCw7QcK" *ngIf="!aboutUs.image" alt="" style="height: 300px;" >
            </div>

        </div>

        <br>

        <div class="row mt-5">

            <div class="col-lg-3 text-center" (click)="selectAboutUs('historia')">
                <div class="rounded-circle div-round" id="div-historia">
                    <h1>
                        <i class="fas fa-landmark icn-about"></i>
                    </h1>
                </div>
                <br>
                <p class="text-mayuscula text-size-17" id="p-historia">Historia</p>
            </div>

            <div class="col-lg-3 text-center" (click)="selectAboutUs('mision')">
                <div class="rounded-circle div-round" id="div-mision">
                    <h1>
                        <i class="fas fa-rocket icn-about"></i>
                    </h1>
                </div>
                <br>
                <p class="text-mayuscula text-size-17" id="p-mision">Mision</p>
            </div>

            <div class="col-lg-3 text-center" (click)="selectAboutUs('vision')">
                <div class="rounded-circle div-round" id="div-vision">
                    <h1>
                        <i class="fas fa-binoculars icn-about"></i>
                    </h1>
                </div>
                <br>
                <p class="text-mayuscula text-size-17" id="p-vision">Vision</p>
            </div>

            <div class="col-lg-3 text-center" (click)="selectAboutUs('valores')">
                <div class="rounded-circle div-round" id="div-valores">
                    <h1>
                        <i class="fas fa-hand-holding-heart icn-about"></i>
                    </h1>
                </div>
                <br>
                <p class="text-mayuscula text-size-17" id="p-valores">Valores</p>
            </div>

        </div>

    </div>
    

    <br>
</section> -->

<app-footer></app-footer>