<br>
<body class="d-flex flex-column">
    <!-- For demo purpose -->
    <!-- <div class="container-fluid flex-grow-1 flex-shrink-0">
      <div class="px-lg-5">
        <div class="row py-5">
          <div class="col-lg-12 mx-auto text-white text-center">
            <h1 class="display-4">Bootstrap 4 footer</h1>
            <p class="lead mb-0">Build a nicely styled light footer using Bootstrap 4.</p>
            <p class="lead">Snippet by <a href="https://bootstrapious.com/snippets" class="text-white">
                          <u>Bootstrapious</u></a>
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <!-- End -->
  
  
    <!-- Footer -->
    <footer class="" style="background-color:#EDEDED">
      <div class="container py-5">
        <div class="row py-4">
          <div class="col-lg-4 col-md-6 mb-4 mb-lg-0" style="line-height: 1.5em;">   
            <div>
                <img class="logo pb-2  " [src]="urlBase+configuracion?.imgLogo+'?alt=media'" alt="" width="100px" height="auto" style="display: flex; justify-content: center; align-items: center;">
            </div>            
            <p class="text-muted"><strong class=""> Dirección:</strong>  {{informacion?.direccion}} </p>
            <p class="text-muted"><strong class=""> Email:</strong>  {{informacion?.email}} </p>
            <p class="text-muted"><strong class=""> Teléfono:</strong>  {{informacion?.telefonos}} </p>

          </div>
          <div class="col-lg-2 col-md-6 col-6 mb-4 mb-lg-0">
            <h6 class="text-uppercase font-weight-bold mb-4">PÁGINAS</h6>
            <ul class="list-unstyled mb-0">
              <li class="mb-2"><a  class="nav-link active" [routerLink]="[ '/about-us' ]" routerLinkActive="active" class="text-muted">Historia</a></li>
              <li class="mb-2"><a type="button"  [routerLink]="[ '/about-us' ]" routerLinkActive="active" class="text-muted">Misión</a></li>
              <li class="mb-2"><a type="button"  [routerLink]="[ '/about-us' ]" routerLinkActive="active"  class="text-muted">Visión</a></li>
              <li class="mb-2"><a type="button" [routerLink]="[ '/about-us' ]" routerLinkActive="active"   class="text-muted">Valores </a></li>
              <li class="mb-2"><a  [routerLink]="[ '/about-us' ]" routerLinkActive="active" class="text-muted"  type="button" >Términos y condiciones</a></li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-6 col-6 mb-4 mb-lg-0">
            <h6 class="text-uppercase font-weight-bold mb-4">CONTACTO</h6>
            <ul class="list-unstyled mb-0">
              <li class="mb-2"><a (click)="goToSocialNetwork(informacion?.facebook)" target="_blank" title="facebook" class="text-dark " ><i class="fa fa-facebook" [ngStyle]="{'color':colorLeter() }" id="iFB"> </i> Facebook </a></li>
              <li class="mb-2"><a (click)="goToSocialNetwork(informacion?.instagram)" target="_blank" title="Instagram" class="text-dark "><i class="fa fa-instagram" [ngStyle]="{'color':colorLeter() }">  </i> Instagram</a></li>
              <li class="mb-2"><a (click)="goToSocialNetwork(informacion?.youtube)" target="_blank" title="Youtube" class="text-dark "><i class="fa fa-youtube" [ngStyle]="{'color':colorLeter() }">  </i> Youtube</a> </li>
              <li class="mb-2"><a (click)="goToSocialNetwork(informacion?.tiktok)" target="_blank" title="TikTok" class="text-dark "><i class="fa fa-tiktok" [ngStyle]="{'color':colorLeter() }"> </i> TikTok</a></li>
              <li class="mb-2"><a (click)="goToSocialNetwork(informacion?.twitter)" target="_blank" title="twitter" class="text-dark "><i class="fa fa-twitter" [ngStyle]="{'color':colorLeter() }"> </i> Twitter</a> </li>

            </ul>
          </div>
          <div class="col-lg-4 col-md-6 mb-lg-0">
            <h6 class="text-uppercase font-weight-bold mb-4">Suscríbete</h6>
            <p class="text-muted mb-4">Suscribase a nuestra lista de noticias y manténganse siempre actualizado</p>
            <div class="p-1 rounded border">
              <div class="input-group">
                <input type="email" placeholder="Ingrese su correo electrónico" aria-describedby="button-addon1" class="form-control border-0 shadow-0">
                <div class="input-group-append">
                  <button id="button-addon1" type="submit" class="btn btn-link rounded-pill"><i class="fa fa-paper-plane" [ngStyle]="{'color':colorLeter() }"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Copyrights -->
      <div class="py-2" id="divFooter">
        <div class="container text-center" type="button" (click)="goToSocialNetwork('https://www.sofpymes.com')">
          <p class=" mb-0 py-2">{{footerText}}</p>
        </div>
      </div>
    </footer>
    <!-- End -->
  
  </body>

<!-- 
<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true }" [template]="">
</ngx-loading>

<section class="container-fluid div-footer">

    <div class="row text-center text-minuscula text-light div-content pt-2 pb-2 " id="footer-section">

        <div class="col-lg-4 p-0 100">
            <div class="text-justify bg-dark pt-1 pb-1 text-center">
                <span class="text-tituto-general text-size-18" style="visibility:hidden;"> VIDEO PROMOCIONAL </span>
            </div>
            <div class="pt-3 pb-2 pl-2 w-100 h-100">
                <iframe width="100%" height="85%" [src]="videoYoutube" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>

        <div class="col-lg-3 p-0 w-100">
            <div class="text-justify bg-dark pt-1 pb-1 text-center">
                <span class="text-tituto-general text-size-18"> CONTACTOS </span>
            </div>
            <div class="text-justify pt-3 pb-3 mt-2" id="div-contactos">

                <div class="row">
                    <div class="col-2 pl-0 pr-0 text-right">
                        <i class="fas fa-map-marker-alt text-size-17 maps" id="icn-direccion"></i>
                    </div>
                    <div class="col-10 text-size-14 font-weight-bold">
                        {{informacion?.direccion}}
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-2 pl-0 pr-0 text-right">
                        <i class="fas fa-phone-alt text-size-17 telefono" id="icn-telefono"></i>
                    </div>
                    <div class="col-10 text-size-14 font-weight-bold">
                        {{informacion?.telefonos}}
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-2 pl-0 pr-0 text-right">
                        <i class="fas fa-envelope text-size-17 gmail" id="icn-email"></i>
                    </div>
                    <div class="col-10 text-size-14 font-weight-bold">
                        {{informacion?.email}}
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-2 pl-0 pr-0 text-right">
                        <i class="fab fa-whatsapp text-size-17" id="icn-whats"></i>
                    </div>
                    <div class="col-10 text-size-14 font-weight-bold">
                        {{informacion?.whatsapp}}
                    </div>
                </div>

                <div class="row mt-3 tipo-cursor" (click)="goLocation()">
                    <div class="col-2 pl-0 pr-0 text-right">
                        <img src="../../../assets/img/marker-google.png" width="14px" height="auto">
                    </div>
                    <div class="col-10 text-size-14 font-weight-bold">
                        Como llegar (Geolocalización)
                    </div>
                </div>

            </div>
        </div>

        <div class="col-lg-3 p-0 w-100">
            <div class="text-justify bg-dark pt-1 pb-1 text-center">
                <span class="text-tituto-general text-size-18"> NUESTRA EMPRESA </span>
            </div>
            <div class="text-justify pt-3 pb-3 mt-2" id="div-empresa">
                <p class="text-left pl-5 text-minuscula-texto tipo-cursor">
                    <span class="list-info" (click)="goToAboutUs('historia')">
                        <i class="fas fa-landmark"></i>
                        <span class="ml-3 text-size-14 font-weight-bold">Historia</span>
                    </span>
                </p>
                <p class="text-left pl-5 text-minuscula-texto tipo-cursor">
                    <span class="list-info" (click)="goToAboutUs('mision')">
                        <i class="fas fa-rocket"></i>
                        <span class="ml-3 text-size-14 font-weight-bold">Misión</span>
                    </span>
                </p>
                <p class="text-left pl-5 text-minuscula-texto tipo-cursor">
                    <span class="list-info" (click)="goToAboutUs('vision')">
                        <i class="fas fa-binoculars"></i>
                        <span class="ml-3 text-size-14 font-weight-bold">Visión</span>
                    </span>
                </p>
                <p class="text-left pl-5 text-minuscula-texto tipo-cursor">
                    <span class="list-info" (click)="goToAboutUs('valores')">
                        <i class="fas fa-hand-holding-heart"></i>
                        <span class="ml-3 text-size-14 font-weight-bold">Valores</span>
                    </span>
                </p>
                <p class="text-left pl-5 text-minuscula-texto tipo-cursor">
                    <span class="list-info">
                        <i class="fas fa-clipboard-list"></i>
                        <span class="ml-3 text-size-14 font-weight-bold">Términos y Condiciones</span>
                    </span>
                </p>
            </div>
        </div>

        <div class="col-lg-2 p-0 100">
            <div class="text-justify bg-dark pt-1 pb-1 text-center">
                <span class="text-tituto-general text-size-18"> REDES SOCIALES </span>
            </div>
            <div class="text-justify pt-3 pb-3 mt-2" id="div-redes">
                <p class="text-left pl-5 text-minuscula-texto tipo-cursor">
                    <span class="list-info" (click)="goToSocialNetwork(informacion?.facebook)">
                        <i class="fab fa-facebook-square text-size-19 facebook"></i>
                        <span class="ml-3 text-size-14 font-weight-bold">Facebook</span>
                    </span>
                </p>
                <p class="text-left pl-5 text-minuscula-texto tipo-cursor">
                    <span class="list-info" (click)="goToSocialNetwork(informacion?.youtube)">
                        <i class="fab fa-youtube text-size-19 youtube youtube"></i>
                        <span class="ml-3 text-size-14 font-weight-bold">Youtube</span>
                    </span>
                </p>
                <p class="text-left pl-5 text-minuscula-texto tipo-cursor">
                    <span class="list-info" (click)="goToSocialNetwork(informacion?.instagram)">
                        <i class="fab fa-instagram-square text-size-19 instagram"></i>
                        <span class="ml-3 text-size-14 font-weight-bold">Instagram</span>
                    </span>
                </p>
                <p class="text-left pl-5 text-minuscula-texto tipo-cursor">
                    <span class="list-info" (click)="goToSocialNetwork(informacion?.twitter)">
                        <i class="fab fa-twitter text-size-19 twitter"></i>
                        <span class="ml-3 text-size-14 font-weight-bold">Twitter</span>
                    </span>
                </p>
                <p class="text-left pl-5 text-minuscula-texto tipo-cursor">
                    <span class="list-info" (click)="goToSocialNetwork(informacion?.tiktok)">
                        <i class="fab fa-tiktok text-size-19 tiktok"></i>
                        <span class="ml-3 text-size-14 font-weight-bold">Tik Tok</span>
                    </span>
                </p>
            </div>
        </div>

    </div>

    <div class="row text-minuscula text-size-14 div-content bg-dark text-light">
        <div class="col-lg-12 text-center tipo-cursor" (click)="goToSocialNetwork('https://www.sofpymes.com')">
            {{footerText}}
            <i class="fas fa-mouse ml-2"></i>
        </div>
    </div>

</section> -->

<!-- #fbc4a8 -->