import { Component, OnInit, ViewChild, TemplateRef, ElementRef, AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, SimpleChanges, OnChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { ActivatedRoute, Params } from '@angular/router';

import { ServicesService } from '../../shared/services/services.service';
import { LoginUserComponent } from '../../pages/login/login-user/login-user.component';
import { environment } from 'src/environments/environment';
import ImageZoom from 'js-image-zoom';
import { log } from 'console';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit, AfterViewInit, OnChanges  {
  @ViewChild("roomReservationModal", {}) roomReservationModal: TemplateRef<any>;
  @ViewChild("createReservationModal", {}) createReservationModal: TemplateRef<any>;
  @ViewChild("resultReservationModal", {}) resultReservationModal: TemplateRef<any>;
  @ViewChild("paypalModal", {}) paypalModal: TemplateRef<any>;

  @ViewChild('imageContainer', { static: false }) imageContainer!: ElementRef;
  @ViewChild('imageElement', { static: false }) imageElement!: ElementRef;

  zoomOptions = {
    zoomWidth: 100,
    // zoomHeight: 400,
    scale: 1.5,
    offset: { vertical: 0, horizontal: 10 },
    zoomPosition: 'right',
    zoomLensStyle: 'opacity: 0.2; background-color: black;',
    zoomType: 'lens',
    lens: true,

  };
  
  private imageZoomInstance: any; // Guarda la instancia de ImageZoom


  public loadingAll = false;
  public typesRooms: any = [];
  public rooms: [];
  public columnSize = 'col-lg-4 text-center mt-3';
  public roomSelected: any;

  public companyNane;
  public closeResult: string;
  public imagenBanner = [];
  public calendar = {
    date_current: ''
  }
  //// Calendariooo
  week: any = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo"
  ];

  reservation: any = {};
  monthSelect: any[];
  dateSelect: any;
  dateValue: any;

  public urlDrive;;
  public defaultBanner;

  public configuracion: any = {};
  public information: any = {};

  public resultReserv = {
    status: true,
    msg: "",
    checkin: "",
    checkout: "",
    habitacion: "",
    reserva: "",
    cliente: "",
    amount: 0,
    subtot: 0,
    descount: 0,
    surchargeTc: 0,
    total: 0,
    typeTransaction: 0,
    numeroPersonas: 0,
    precioPorPersona: 0
  }

  public paymentButtons = {
    payPhone: {
      rta: false,
      data: {}
    },
    dataFast: {
      rta: false,
      data: {}
    },
    placetoPay: {
      rta: false,
      data: {}
    },
    payPal: {
      rta: false,
      data: {}
    },
  };

  // Boton de paypal
  public payPalConfig?: IPayPalConfig;

  public transferBank = {
    view: false,
    number: '',
    name: '',
    bank: ''
  }

  public persons = {
    size: 1,
    arr: [1]
  }
   colorPrimario:any;
   colorLetra:any;
   rutaUrl=environment.firebaseUrl;
   myThumbnail:any;
   myFullresImage:any;

   modalOpen: boolean = false;
   imageElementRef: ElementRef | null = null;
   imageContainer2: ElementRef | null = null;

   cantHuesped=1;

  constructor(
    private toaster: ToastrService,
    private webService: ServicesService,
    public router: Router,
    private modalCtrl: NgbModal,
    private elementRef: ElementRef,
    private activateRoute: ActivatedRoute,
    // private imgTec: ImageZoom.Options

  ) {


  }
  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
    console.log('cambios ==== > ', changes);
    
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log('cambios ==== > ', changes);
    
  //   if (changes['modalOpen'] && this.modalOpen) {
  //     this.initializeZoom();
  //   }
  // }

  async ngOnInit() {
    // await this.webService.getUrlDrive().then((res: any) => {
    //   this.urlDrive = res.urlDrive;
    //   this.defaultBanner = res.defaultBanner;
    // })
    await this.getInformacion();
    await this.getConfiguracion();
    await this.getRoomTypes();
    await this.getBanner();
    await this.getPaymentGateway();
    // await this.initializeZoom();

    await this.activateRoute.params.subscribe(async (params: Params) => {
      if (params.id) {
        // //console.log("Ahi fuee", params.id);
        await this.getRoomPromotion(params.id);
      } else {
        // //console.log("No hacer nada");
      }
    });
  }

  ngAfterViewInit(): void {
      this.initializeZoom();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  // initializeZoom() {
    
  //   this.imageElement.nativeElement.onload = () => {
  //     if (this.imageZoomInstance) {
  //       this.imageZoomInstance.kill(); // Destruye la instancia previa si existe
  //     }
  //     this.imageZoomInstance = new ImageZoom(this.imageContainer.nativeElement, this.zoomOptions); // Crea nueva instancia
  //   };
    
  // }

  // initializeZoom(): void {
  //   if (!this.imageElement || !this.imageElement.nativeElement) {
  //     console.warn('Elemento de imagen no disponible');
  //     return;
  //   }

  //   this.imageElement.nativeElement.onload = () => {
  //     if (this.imageZoomInstance) {
  //       this.imageZoomInstance.kill(); // Destruye la instancia previa si existe
  //     }
  //     this.imageZoomInstance = new ImageZoom(this.imageContainer.nativeElement, this.zoomOptions); // Crea nueva instancia
  //   };
  // }

  initializeZoom() {

    let imageElement = document.getElementById('imageElement') as HTMLImageElement;
    let imageContainer = document.getElementById('imageContainer') as HTMLImageElement;


    if (imageElement && imageContainer) {
      // Crea un ElementRef para el imageElement
      this.imageElementRef  = new ElementRef(imageElement);
      this.imageContainer2  = new ElementRef(imageContainer);
      console.log(' ----- ', this.imageElementRef );
      console.log(' ----- ', this.imageContainer2 );
      
 
    this.imageElementRef.nativeElement.onload = () => {
      if (this.imageZoomInstance) {
        this.imageZoomInstance.kill(); // Destruye la instancia previa si existe
      }
      this.imageZoomInstance = new ImageZoom(this.imageContainer2.nativeElement, this.zoomOptions); // Crea nueva instancia
    };
  }
 
  }

 


  private destroyZoomInstance(): void {
    if (this.imageZoomInstance) {
      try {
        this.imageZoomInstance.kill(); // Destruye la instancia previa
      } catch (error) {
        console.warn('Error al destruir la instancia de ImageZoom:', error);
      }
      this.imageZoomInstance = null; // Resetea la referencia de la instancia
    }
  }

  reinitializeZoom(): void {
    this.initializeZoom(); // Reinicializa el zoom con la nueva imagen
  }


  colorUno(){
    // //console.log('P',this.colorPrimario );
    return this.colorPrimario 
    
  }
  colorLeter(){
    return this.colorLetra;
  }

  async getConfiguracion() {
    this.webService.getConfiguracion().then(async (data: any) => {
      if (!data.error) {
        this.configuracion = data[0];
        this.colorPrimario = this.configuracion.colorPrincipal;
        this.colorLetra = this.configuracion.colorLetra;
        
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async getInformacion() {
    this.webService.getInformacion().then((resinfo: any) => {
      this.companyNane = resinfo[0].nombre;
      this.information = resinfo[0];
      if (this.information.numeroCuenta) {
        this.transferBank = {
          view: false,
          number: this.information.numeroCuenta,
          name: this.information.nombreCuenta,
          bank: this.information.nombreBanco,
        }
      } else {
        this.transferBank.view = false;
      }
    });
  }

  async getBanner() {
    this.webService.getImageBanner().then((resbanner: any) => {
      console.log("BAnnerr", resbanner);
      this.imagenBanner = resbanner;
    });
  }

  async getRoomTypes() {
    this.loadingAll = true;
    await this.webService.getUrlEmpresa().then(async (url_billing) => {
      if (url_billing) {
        this.webService.getRoomTypesSerivice(url_billing).then(async (restypes: any) => {
          if (!restypes.error) {
            if (restypes.rta == true) {
              this.columnSize = this.obtainSizeColumn(restypes.data.length);
              this.typesRooms = restypes.data;
              console.log('typesRooms', this.typesRooms);
              this.loadingAll = false;

            } else {
              this.toaster.warning('No se ha encontrado resultados', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              this.loadingAll = false;

            }
          } else {
            this.toaster.error('Error al conectar con el servidor', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            this.loadingAll = false;

          }
        });
      } else {
        //console.log("No se ha encontrado la url");
        this.loadingAll = false;

      }
    });
  }

  obtainSizeColumn(size) {
    let col_size = '';
    switch (true) {
      case (size == 1):
        col_size = 'col-lg-4';
        break;
      case (size == 2):
        col_size = 'col-lg-6';
        break;
      case (size >= 3):
        col_size = 'col-lg-4';
        break;
      default:
    }
    return col_size;
  }

  async seeMoreDescription(group) {
    group.view = !group.view;
  }

  async getRooms(typeRoom) {
    this.loadingAll = true;
    await this.webService.getUrlEmpresa().then(async (url_billing) => {
      if (url_billing) {
        await this.webService.getRoomsSerivice(url_billing, typeRoom).then(async (resroom: any) => {
          if (!resroom.error) {
            if (resroom.rta == true) {
              // //console.log("habitaciones", resroom);
              this.modalSelectedRoomReservation(resroom.data, this.roomReservationModal);
            } else {
              this.toaster.warning('No se ha encontrado resultados', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          } else {
            this.toaster.error('Error al conectar con el servidor', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      } else {
        //console.log("No se ha encontrado la url");
      }
    });
    this.loadingAll = false;
  }

  async modalSelectedRoomReservation(habitaciones, roomReservationModal) {
    console.log("Primer habitacion");

    this.persons = {
      size: 1,
      arr: [1]
    }
    this.rooms = habitaciones;
    //console.log('romms', this.rooms);
    
    await this.webService.saveGroupRoomSelected(this.rooms);
    // Inicializar o encerar la fecha con la que se genera el caledario
    await this.webService.saveCurrenGeneratedCalendar({});
    await this.webService.resetVariables('newYear');
    await this.modalCtrl.open(roomReservationModal, { ariaLabelledBy: 'modal-basic-title', size: 'xl', centered: true, backdrop: 'static', keyboard: false }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      // //console.log("Ok", result);
      await this.modalCreateRoomReservation(result, this.createReservationModal);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //console.log("Cerro", reason);
      this.webService.goHome();
    });
  }

  async modalCreateRoomReservation(room, createReservationModal) {

    console.log("habitacion xxx", room);
    this.modalOpen = true;
    console.log('cambio ',  this.modalOpen );
    // if(this.modalOpen){
    // setTimeout(() => this.initializeZoom(), 2000); // Ajusta el tiempo si es necesario

    // }
    
    // //console.log("capacidad", room.capacidad);
    // //console.log("precios", room.producto.precios);
    this.persons = {
      size: room.capacidad,
      arr: [1]
    }
    await this.webService.resetVariables('checkRoom');
    // this.roomSelected;
    // this.myThumbnail = this.roomSelected?.imagenPrincipal;
    // this.myFullresImage = this.roomSelected?.imagenPrincipal;
    this.reservation = {
      daysMont: {},
      dateSelected: {},
      check: {},
      days: [],
      viewBtnPrevious: true,
      differentMonth: false
    }
    this.loadingAll = true;
    await this.webService.getCurrentDate().then(async (resdate: any) => {
      await this.getCurrentDate(resdate, room);
          // //console.log('resdate',resdate);

    });

    this.loadingAll = false;
    this.roomSelected = room;
    this.myThumbnail = this.roomSelected.imagenPrincipal;
    this.myFullresImage = this.roomSelected.imagenPrincipal;
    // //console.log('resdate',this.roomSelected);
    // Validar permiso, precio por persona e inicial con 1
    if (this.configuracion.habitacion_por_persona == true) {
      this.selectPersonNumber(1);
    }
    // setTimeout(() => this.initializeZoom(), 5000); // Ajusta el tiempo si es necesario

    await this.modalCtrl.open(createReservationModal, { ariaLabelledBy: 'modal-basic-title', size: 'xl', centered: true, backdrop: 'static', keyboard: false }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      console.log('ACA ==> ', result);
      

      // //console.log("Ok", result);
      if (result == 'return') {
        this.webService.getGroupRoomSelected().then((res: any) => {
          if (res.rta == true) {
            this.modalSelectedRoomReservation(res.data, this.roomReservationModal);
          } else {
            //console.log("Cerrar el modal", res);
          }
        });
      } else {
      }
      

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //console.log("Cerro", reason);
      this.webService.goHome();
    });

  }

  // Generar el calendario con la fecha actual
  async getCurrentDate(date, room) {
    // //console.log("habitacion", room);
    let roomReservation;
    this.reservation.room = room;
    await this.webService.getCurrentDate().then(async (resdate: any) => {
      // ****** Probar otras fechas ******
      // //console.log("diiitas", resdate);
      // resdate.number_day = 25;
      // resdate.day_number = 25;
      // ****** *****
      if (date.year == resdate.year && date.month_number == resdate.month_number) {
        // Pasar el numero de dia actual para deshabilitar los que ya han pasado
        date.day_number = resdate.day_number;
        this.reservation.viewBtnPrevious = false;
      } else {
        this.reservation.viewBtnPrevious = true;
      }
    });
    var days = new Array('', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', "Domingo");
    this.calendar.date_current = date.day_name + ', ' + date.day_number + ' de ' + date.month_name + ' del ' + date.year;
    await this.webService.getUrlEmpresa().then(async (url) => {
      let data = {
        id_hab: room.id_hab,
        desde: date.year + "-" + date.month_number + "-01",
        hasta: date.year + "-" + date.month_number + "-" + date.number_day
      }
      await this.webService.getRoomReservationId(url, data).then((restatus: any) => {
        roomReservation = restatus;
      });

    });
    const arrayDays = Object.keys([...Array(date.number_day)]).map((a: any) => {
      a = parseInt(a) + 1;
      const dayObject = moment(`${date.year}-${date.month_number}-${a}`);
      let active = false;
      let available = true;
      let day_format;

      if (a < 10) {
        day_format = "0" + a;
      } else {
        day_format = a;
      }

      if (a >= date.day_number) {
        active = true;
      }

      return {
        name: dayObject.format("dddd"),
        value: a,
        indexWeek: dayObject.isoWeekday(),
        name_es: days[dayObject.isoWeekday()],
        active: active,
        available: available,
        selected: false,
        day_format: day_format,
        date_format: date.year + "-" + date.month_number + "-" + day_format
      };

    });
    this.reservation.daysMont = arrayDays;
    this.reservation.dateSelected = date;

    if (roomReservation.rta == true) {
      for (let r of roomReservation.data) {
        for (let d of this.reservation.daysMont) {
          if (d.date_format >= r.fecha_checkin && d.date_format <= r.fecha_checkout) {
            if (r.estado != 3) {
              d.available = false;
            }
          }
        }
      }
    }

    let min;
    let max;
    await this.webService.getDateCheckRoom().then(async (checkRoom: any) => {

      if (checkRoom.length > 0) {
        let exist = false;
        let days_aux;

        for (let r of checkRoom) {
          if (r.dateSelected.month_number == this.reservation.dateSelected.month_number && r.dateSelected.year == this.reservation.dateSelected.year) {
            days_aux = [Math.min.apply(Math, r.days), Math.max.apply(Math, r.days)];
            this.reservation.days = r.days;
          }
        }

        await this.webService.getPreviousNextDate(this.reservation.dateSelected.month_number, 'previous').then((resmonthprevious) => {
          for (let r of checkRoom) {
            // Condicion para diciembre y enero
            if (r.dateSelected.month_number == '01' && resmonthprevious.month_number == '12') {

            } else {
              if (r.dateSelected.year == resmonthprevious.year && r.dateSelected.month_number <= resmonthprevious.month_number) {
                exist = true;
              }
            }
          }

          if (exist == true) {
            min = 1;
            max = Math.max.apply(Math, days_aux);
          } else {
            min = Math.min.apply(Math, days_aux);
            max = Math.max.apply(Math, days_aux);
          }
        });

        for (let d of this.reservation.daysMont) {
          if (d.value >= min && d.value <= max) {
            d.selected = true;
            // this.reservation.amount = this.reservation.amount + 1;
          } else {
            d.selected = false;
          }
        }
      }

      await this.webService.getCheckInOutGeneral().then(async (resdays: any) => {
        if (resdays) {
          this.loadingAll = true;
          this.reservation.amount = resdays.daysNum;
          await this.webService.getUrlEmpresa().then(async (url) => {
            let info = {
              id_hab: this.reservation.room.id_hab,
              desde: resdays.in,
              hasta: resdays.out
            }
            await this.webService.getRoomReservationId(url, info).then((resreserv: any) => {
              if (resreserv.rta == false) {
                this.reservation.check = resdays;
              } else {
                this.viewMessageAlert("Fecha seleccionada no se encuentran disponible.", "warning");
                this.reservation.check = {};
              }
            });
          });
          this.loadingAll = false;
        } else {
          this.reservation.amount = 0;
          this.reservation.check = {};
        }
      });

    });
    await this.webService.saveCurrenGeneratedCalendar(date);
  }

  async getNextMonth(roomSelected, type) {
    this.loadingAll = true;
    await this.webService.getCurrenGeneratedCalendar().then(async (resdate) => {
      // //console.log("mes " + parseInt(resdate.month_number) + ", " + resdate.month_name);
      await this.webService.getPreviousNextDateButton(resdate.month_number, type).then(async (resnext) => {
        await this.getCurrentDate(resnext, roomSelected);
        this.reservation.differentMonth = true;
      });
    });
    this.loadingAll = false;
  }

  async getPreviousMonth(roomSelected, type) {
    this.loadingAll = true;
    await this.webService.getCurrenGeneratedCalendar().then(async (resdate) => {
      // //console.log("mes se vaa " + parseInt(resdate.month_number) + ", " + resdate.month_name);
      await this.webService.getPreviousNextDateButton(resdate.month_number, type).then(async (resnext) => {
        await this.getCurrentDate(resnext, roomSelected);
      });
    });
    this.loadingAll = false;
  }

  async clickDay(day) {
    this.reservation.dateSelected.day_number = 1;
    // //console.log("param 1", this.reservation.dateSelected);
    // //console.log("param 2", day);
    let min;
    let max;
    this.loadingAll = true;
    await this.webService.saveDateCheckRoom(this.reservation.dateSelected, day).then(async (res: any) => {
      if (res.days.length > 0) {
        let days;
        let exist = false;
        await this.webService.getDateCheckRoom().then(async (checkRoom) => {
          if (checkRoom.length > 0) {
            await this.webService.getPreviousNextDate(res.dateSelected.month_number, 'previous').then((resmonthprevious) => {
              for (let r of checkRoom) {
                // //console.log(r.dateSelected.year + '==' + resmonthprevious.year + '=' + (r.dateSelected.year == resmonthprevious.year));
                // //console.log(r.dateSelected.month_number + '<=' + resmonthprevious.month_number + '=' + (r.dateSelected.month_number <= resmonthprevious.month_number));
                // Condicion para diciembre y enero
                if (r.dateSelected.month_number == '01' && resmonthprevious.month_number == '12') {

                } else {
                  if (r.dateSelected.year == resmonthprevious.year && r.dateSelected.month_number <= resmonthprevious.month_number) {
                    exist = true;
                  }
                }
              }
            });
          }

          if (exist == true) {
            days = [1, Math.max.apply(Math, res.days)];
            // //console.log("ifff, days", days);
          } else {
            days = res.days;
            // //console.log("else, days", days);
          }

          await this.webService.getMaxMinDay(days).then((resm: any) => {
            min = resm.min;
            max = resm.max;
          });

        });

      } else {
        min = 0;
        max = 0;
      }
    });
    // Aqui marca los dias que el cliente selecciona
    for (let d of this.reservation.daysMont) {
      if (d.value >= min && d.value <= max) {
        d.selected = true;
        // this.reservation.amount = this.reservation.amount + 1;
      } else {
        d.selected = false;
      }
    }
    await this.webService.getCheckInOutGeneral().then(async (resdays: any) => {

      if (resdays) {

        this.loadingAll = true;

        this.reservation.amount = resdays.daysNum;

        await this.webService.getUrlEmpresa().then(async (url) => {
          let info = {
            id_hab: this.reservation.room.id_hab,
            desde: resdays.in,
            hasta: resdays.out
          }
          await this.webService.getRoomReservationId(url, info).then((resreserv: any) => {
            if (resreserv.rta == false) {
              this.reservation.check = resdays;
            } else {
              this.viewMessageAlert("Fecha seleccionada no se encuentran disponible.", "warning");
              this.reservation.check = {};
            }
          });
        });

        this.loadingAll = false;

      } else {
        this.reservation.amount = 0;
        this.reservation.check = {};
      }

    });
    this.loadingAll = false;
  }

  async viewMessageAlert(mesage, tipo) {
    if (tipo == 'warning') {
      this.toaster.warning(mesage, '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
    if (tipo == 'danger') {
      this.toaster.error(mesage, '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async SelectImageReservation(room, image) {
    room.imagenPrincipal = image;
    this.reinitializeZoom();  // Reinicializa el zoom
  }

  async verifyLoginClient(room, reservation, type, detailReservationModal) {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (login: any) => {
      if (login.rta == true) {
        await this.webService.calculateReservationCost(room, reservation, this.configuracion, type).then(async (rescost: any) => {
          // //console.log("Calcular costo", rescost);
          this.resultReserv.habitacion = room.type + " #" + room.nro;
          this.resultReserv.cliente = login.data.nombres + " " + login.data.apellidos;
          this.resultReserv.typeTransaction = rescost.calculations.typeTransaction;
          this.resultReserv.checkin = reservation.check.in;
          this.resultReserv.checkout = reservation.check.out;
          this.resultReserv.subtot = rescost.calculations.subtot;
          this.resultReserv.descount = rescost.percentage.descount;
          this.resultReserv.surchargeTc = rescost.percentage.surchargeTc;
          this.resultReserv.total = rescost.calculations.total;
          this.resultReserv.amount = reservation.amount;
          await this.modalDetailReservation(detailReservationModal, room, reservation, login.data, type, rescost);
        });

      } else {
        await this.loginClient();
      }
    });
  }

  async loginClient() {
    this.modalCtrl.open(LoginUserComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm', keyboard: false }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, async (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // //console.log(reason);
      // this.webService.getGroupRoomSelected().then((res: any) => {
      //   this.modalSelectedRoomReservation(res.data, this.roomReservationModal);
      // });
    });
  }

  async modalDetailReservation(detailReservationModal, room, reservation, login, type, costCalculated) {
    // //console.log("modal de precios");
    // //console.log("quiiiii", this.resultReserv);



    if (type == 'transfer') {
      this.transferBank.view = true;
    }
    this.modalCtrl.open(detailReservationModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm', backdrop: 'static', keyboard: false }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      // this.restoreResultReserv();
      if (type == 'normal' || type == 'transfer') {
        // //console.log("pago normal");
        await this.createReservaRoom(room, reservation, login, costCalculated, this.configuracion, type);
      }
      if (type == 'paypal') {
        //  //console.log("pago con pay pal");
        await this.payPalPayment(room, reservation, login, costCalculated, this.configuracion, this.paymentButtons.payPal, type);
        this.modalCtrl.open(this.paypalModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm', backdrop: 'static', keyboard: false }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
          //console.log("Close", result);
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          //console.log("Cancelar", reason);
        });
      }
      this.transferBank.view = false;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // //console.log(reason);
      this.transferBank.view = false;
      // this.restoreResultReserv();
    });
  }

  async createReservaRoom(room, reservation, login, costCalculated, configuracion, type) {
    this.loadingAll = true;
    let date = {
      cedula: login.cedula,
      fecha_checkin: reservation.check.in,
      fecha_checkout: reservation.check.out,
      id_habitacion: room.id_hab,
      estado: 2,
      id_empleado: 0,
      pin: 0,
      checkout: 0,
      hora_checkin: "",
      hora_checkout: "",
      observaciones: ""
    }

    // Validar permiso, precio por persona e inicial con 1
    if (this.configuracion.habitacion_por_persona == true) {
      date.observaciones = "Numero días : " + costCalculated.calculations.amount + " / Precio por persona: " + this.resultReserv.precioPorPersona + " / Número Personas : " + this.resultReserv.numeroPersonas + " / SubTotal : " + costCalculated.calculations.subtot + " / Descuento : " + costCalculated.percentage.descount + " / Total " + costCalculated.calculations.total;
    } else {
      date.observaciones = "Numero días : " + costCalculated.calculations.amount + " / Precio : " + room.precio + " / SubTotal : " + costCalculated.calculations.subtot + " / Descuento : " + costCalculated.percentage.descount + " / Total " + costCalculated.calculations.total;
    }

    await this.webService.getUrlEmpresa().then(async (url) => {
      console.log('ENTRA ACA');
      
      await this.webService.postGeneral2(url+'create_reserva_hotel',date).subscribe(async(data:any)=>{
        console.log('data', data);
        if(data.rta){
               this.resultReserv.status = true;
          this.resultReserv.msg = "Reserva creada exitosamente";
          this.resultReserv.reserva = data.id_res;
                    await this.webService.createReservationEmailBody(data.id_res, room, reservation, login, costCalculated, configuracion, type, this.resultReserv).then(async (resbody) => {
            await this.webService.sendMailService(resbody).then(async (resmail: any) => {
              //console.log(resmail);
            });
          });

        }else{
          this.resultReserv.status = false;
          this.resultReserv.msg = "Ha ocurrido un error, intente nuevamente"; 
        }
        
      })
      // await this.webService.createReserva(url, date).then(async (resreser: any) => {
      //   if (resreser.rta == true) {
      //     this.resultReserv.status = true;
      //     this.resultReserv.msg = "Reserva creada exitosamente";
      //     this.resultReserv.reserva = resreser.res.id_res;

      //     await this.webService.createReservationEmailBody(resreser.res.id_res, room, reservation, login, costCalculated, configuracion, type, this.resultReserv).then(async (resbody) => {
      //       await this.webService.sendMailService(resbody).then(async (resmail: any) => {
      //         //console.log(resmail);
      //       });
      //     });

      //   } else {
      //     this.resultReserv.status = false;
      //     this.resultReserv.msg = "Ha ocurrido un error, intente nuevamente";
      //   }
      // });
    });

    await this.modalCtrl.dismissAll();
    await this.modalResultReservation();

    this.loadingAll = false;

  }

  async modalResultReservation() {
    this.modalCtrl.open(this.resultReservationModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm', backdrop: 'static', keyboard: false }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      // this.modalCtrl.dismissAll();
      this.restoreResultReserv();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //console.log(reason);
      // this.modalCtrl.dismissAll();
      this.restoreResultReserv();
    });
  }

  async restoreResultReserv() {
    this.resultReserv = {
      status: true,
      msg: "",
      checkin: "",
      checkout: "",
      habitacion: "",
      reserva: "",
      cliente: "",
      typeTransaction: 0,
      amount: 0,
      subtot: 0,
      descount: 0,
      surchargeTc: 0,
      total: 0,
      numeroPersonas: 0,
      precioPorPersona: 0
    }
  }

  async getPaymentGateway() {
    await this.webService.getPaymentGateway().then((respayment: any) => {
      // //console.log(respayment);

      if (respayment.rta == true) {
        this.paymentButtons = {
          payPhone: {
            rta: respayment.data.payPhone.rta,
            data: respayment.data.payPhone.data
          },
          dataFast: {
            rta: respayment.data.dataFast.rta,
            data: respayment.data.dataFast.data
          },
          placetoPay: {
            rta: respayment.data.placetoPay.rta,
            data: respayment.data.placetoPay.data
          },
          payPal: {
            rta: respayment.data.payPal.rta,
            data: respayment.data.payPal.data
          },
        };
      }
      // //console.log(this.paymentButtons);

    });
  }

  async payPalPayment(room, reservation, login, costCalculated, configuracion, payPal, type) {
    costCalculated.calculations.totalRounded = parseFloat((costCalculated.calculations.total).toFixed(2));
    costCalculated.calculations.subtotDescount = parseFloat((costCalculated.calculations.subtotDescount).toFixed(2));

    this.payPalConfig = {
      currency: 'USD',
      clientId: payPal.data.token,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          description: "Identificador de la transacción",
          amount: {
            currency_code: 'USD',
            value: costCalculated.calculations.totalRounded,
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: costCalculated.calculations.totalRounded
              }
            }
          },
          items: this.webService.getDataPaypal(room, reservation, costCalculated)
        }]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'horizontal'
      },
      onApprove: async (data, actions) => {
        //console.log('onApprove - transaction was approved, but not authorized', data, actions);
        await this.createReservaRoom(room, reservation, login, costCalculated, configuracion, type);
      },
      onClientAuthorization: async (data) => {
        //console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
      },
      onCancel: (data, actions) => {
        //console.log('OnCancel', data, actions);
        this.modalCtrl.dismissAll();
        this.webService.getGroupRoomSelected().then((res: any) => {
          if (res.rta == true) {
            this.modalSelectedRoomReservation(res.data, this.roomReservationModal);
          } else {
            //console.log("Cerrar el modal", res);
          }
        });
      },
      onError: err => {
        this.modalCtrl.dismissAll();
        //console.log('OnError', err);
      },
      onClick: (data, actions) => {
        //console.log('onClick', data, actions);
      },
    };
  }

  async getRoomPromotion(id_hab) {
    this.loadingAll = true;
    await this.webService.getUrlEmpresa().then(async (url_billing) => {
      if (url_billing) {

        let room;
        let roomQuery = {
          tabla: "habitacion",
          atributo: "id_hab",
          valor_atributo: id_hab,
          filas: "*"
        }

        await this.webService.getDataTable(url_billing, roomQuery).then(async (reshab: any) => {
          if (reshab.rta == true) {
            room = reshab.data;
            let typeRoom;

            let typeRoomQuery = {
              tabla: "tipo_habitacion",
              atributo: "id_tiphab",
              valor_atributo: room[0].id_tiphab,
              filas: "*"
            }

            await this.webService.getDataTable(url_billing, typeRoomQuery).then(async (restype: any) => {
              if (restype.rta == true) {
                typeRoom = restype.data[0];
              } else {
                this.toaster.error('Tipo de habitación no encontrada, comuniquese con el administrador', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              }
            })

            for (let r of room) {
              r.view = false;
              r.selected = false;
              r.precio = parseFloat(r.precio);
              await this.webService.getMainImageRoom(r).then((resimg) => {
                r.imagenPrincipal = resimg.imagenPrincipal;
                r.imagenes = resimg.imagenes;
              });
              r.type = typeRoom.nombre;
              if (r.descripcion) {
                if (r.descripcion.length <= 40) {
                  r.desc = r.descripcion;
                  r.vermas = false;
                  r.icn_view = false;
                } else {
                  r.desc = r.descripcion.slice(0, 35) + '...';
                  r.vermas = true;
                  r.icn_view = true;
                }
              } else {
                r.desc = "Para descansar como en casa."
              }
              if (this.configuracion.porcentajePrecioOferta > 0) {
                await this.webService.calculatePriceOfert(parseFloat(r.precio), this.configuracion.porcentajePrecioOferta).then((resoffer) => {
                  r.offerPrice = resoffer;
                });
              } else {
                r.offerPrice = 0;
              }
            }

            let a = {
              id_tiphab: room[0].id_tiphab,
              nombre: typeRoom.nombre
            }

            await this.webService.getRoomsSerivice(url_billing, a).then(async (resroom: any) => {
              if (resroom.rta == true) {
                this.webService.saveGroupRoomSelected(resroom.data);
              }
              this.modalSelectedRoomReservation(resroom.data, this.roomReservationModal);
            });

            //console.log("habitacion", room);

            await this.modalCreateRoomReservation(room[0], this.createReservationModal);

          } else {
            this.toaster.error('Habitación no encontrada, comuniquese con el administrador', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }

        });
      } else {
        //console.log("No se pudo obtener la url");

      }
    });
    this.loadingAll = false;
  }

  selectPersonNumber(e) {
    console.log('e ===> ',e);
    //console.log('rooms', this.rooms);
 
    this.roomSelected =  this.rooms.filter((data:any)=> data.id_hab == this.roomSelected.id_hab);
    this.roomSelected = this.roomSelected[0];    
    this.persons.arr = [];
    let value = parseInt(e);
    this.cantHuesped=value;
    let capacity = parseInt(this.roomSelected.capacidad);
    console.log(value+ ' > ' + capacity + ' = ' + (value > capacity));

    if (value > 1) {
       console.log('ENTRA cuando es mayor a 1 ', value);
       
      if (value > capacity) {
        value = capacity;
      }

    } else {
      value = 1
    }

    console.log('value ==> ', value);
    

    for (var i = 1; i <= value; i++) {
      this.persons.arr.push(i);
    }
    console.log(this.roomSelected );
    

    if (this.roomSelected.producto.precios.length > 0) {
      for (var i = 1; i < this.roomSelected.producto.precios.length; i++) {
        console.log('var', i);
        console.log('value', value);
        console.log('value - 1 ', value-1);
        
        if (i == (value - 1)) {
           
          this.roomSelected.precio_persona = this.roomSelected.producto.precios[i].valor_mas_iva;
          this.resultReserv.precioPorPersona = (this.roomSelected.producto.precios[i].valor_mas_iva).toFixed(2);
          this.roomSelected.precio = (this.roomSelected.producto.precios[i].valor_mas_iva * value);
          console.log(this.roomSelected.producto.precios[i].valor_mas_iva);
        }
        // else{
        //   console.log('CASO CONTRARIO ', );
        console.log('ENTRA CUANDO DIGO QUE SON 2'); 
        // }
      }
    }
    console.log('reservation.amount', this.reservation.amount);
    console.log('roomSelected.precio ', this.roomSelected.precio );
    

    this.resultReserv.numeroPersonas = value;

  }

}
