import { Injectable } from '@angular/core';
import { Subject, from, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LocalStorage } from '@ngx-pwa/local-storage';


import { userNames } from '../../collaborator/interface/client';
import { environment } from 'src/environments/environment';
import * as mapboxgl from 'mapbox-gl';

export interface products { }

@Injectable({
  providedIn: 'root'
})


export class ServicesService {

  private MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoicmlja2FyZGFuaWVsIiwiYSI6ImNsZWhiODJkMTAyemozcG56eG00N3V2eHEifQ.V9tE9RybYexj5zGLHN7tGw';

  public apiLoxafree = "https://www.pulpoplace.com:8448/";
  public id_empresa =environment.idShop;

  public roomsSelected = [];
  public calendarCurrent: any;
  public newYear = 0;
  public newYearBtn = 0;
  public checkRoom = [];

  public usr$ = new Subject<userNames>();
  public clientLogin = {
    name: '',
    imagen: '',
    login: false,
    rol: ''
  }

  constructor(
    private http: HttpClient,
    public router: Router,
    private localStorage: LocalStorage
  ) { 
    ( mapboxgl as typeof mapboxgl ).accessToken = this.MAPBOX_ACCESS_TOKEN;
  }

  getIdVideoYoutube(url) {
    if (url.indexOf('?v') > 0) {
      let a = url.split('?v=', 2);
      let id = a[1].split('&', 1);
      return id[0];
    } else {
      return '';
    }
  }

  obtenerCodigoVideo(url) {
    // Expresión regular para buscar el código después de /youtu.be/
    const regex = /youtu\.be\/([^\/]+)/;
    // Ejecutar la búsqueda en la URL proporcionada
    const match = url.match(regex);
    // Si hay coincidencia, devolver el código encontrado; de lo contrario, devolver null
    return match ? match[1] : null;
  }
  

  // *** Servicios Api Loxa free ***

  async getConfiguracion() {
    let url = this.apiLoxafree + "configuracion/" + this.id_empresa;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((data: any) => {
          // console.log("Configuracion", data);
          resolve(data);
        },
          error => {
            resolve(error)
          });
    });
  }

  async getEmpresaService() {
    let url = this.apiLoxafree + "empresa/" + this.id_empresa;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve(data);
        },
          error => {
            resolve(error)
          });
    });
  }

  async getInformacion() {
    let url = this.apiLoxafree + "informacions/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve(data);
        },
          error => {
            resolve(error)
          });
    });
  }

  async getUrlEmpresa() {
    let url_billing;
    await this.getEmpresaService().then((resEmp: any) => {
      if (resEmp.rta == true) {
        url_billing = resEmp.data[0].url_billing;
      } else {
        url_billing = '';
      }

    });
    return url_billing;
  }

  async getImageBanner() {
    let url = this.apiLoxafree + "imagenes-banners/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          if (data.length == 0) {
            data = [{
              id_empresa: this.id_empresa,
              nombre: 'defecto',
              url: '1Ce2aSP-KYX7Ji-JN90xbr7kUqRh5TIAG'
            }];
          }
          resolve(data);
        },
          error => {
            resolve(error)
          });
    });
  }

  async sendMailSupport(data) {
    let url = this.apiLoxafree + "correoSoporte"
    return new Promise((resolve) => {
      this.http.post(url, data).subscribe((res: any) => {
        resolve((res));
      },
        error => {
          resolve(error)
        });
    });
  }

  async getPaymentGateway() {
    let url = this.apiLoxafree + "pasarelaPago/botones/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          // console.log("pasarela pagos", data);
          let res: any;
          let entity = {
            payPhone: {
              rta: false,
              data: {
                nombre: '',
                token: '',
                secret_key: '',
                url: '',
              }
            },
            dataFast: {
              rta: false,
              data: {
                nombre: '',
                token: '',
                secret_key: '',
                url: '',
              }
            },
            placetoPay: {
              rta: false,
              data: {
                nombre: '',
                token: '',
                secret_key: '',
                url: '',
              }
            },
            payPal: {
              rta: false,
              data: {
                nombre: '',
                token: '',
                secret_key: '',
                url: '',
              }
            }
          }

          if (data.length > 0) {

            for (let b of data) {
              if (b.nombre == "PayPhone") {
                entity.payPhone.data.nombre = b.nombre;
                entity.payPhone.data.token = b.token;
                entity.payPhone.data.secret_key = b.secret_key;
                entity.payPhone.data.url = b.url;
                entity.payPhone.rta = true;
              }
              if (b.nombre == "DataFast") {
                entity.dataFast.data.nombre = b.nombre;
                entity.dataFast.data.token = b.token;
                entity.dataFast.data.secret_key = b.secret_key;
                entity.dataFast.data.url = b.url;
                entity.dataFast.rta = true;
              }
              if (b.nombre == "PlacetoPay") {
                entity.placetoPay.data.nombre = b.nombre;
                entity.placetoPay.data.token = b.token;
                entity.placetoPay.data.secret_key = b.secret_key;
                entity.placetoPay.data.url = b.url;
                entity.placetoPay.rta = true;
              }
              if (b.nombre == "PayPal") {
                entity.payPal.data.nombre = b.nombre;
                entity.payPal.data.token = b.token;
                entity.payPal.data.secret_key = b.secret_key;
                entity.payPal.data.url = b.url;
                entity.payPal.rta = true;
              }
            }

            res = {
              rta: true,
              data: entity
            }

          } else {
            res = {
              rta: false,
              data: data
            }
          }

          resolve(res);
        },
          error => {
            resolve(error)
          });
    });
  }

  async createReservationEmailBody(id_reserva, room, reservation, login, costCalculated, configuracion, type, resultReserv) {

    let date;

    await this.getCurrentDate().then((resdate) => {
      date = resdate;
    });

    let html = '<table style="font-family:arial; width:600px;" align="center">';

    html += '<tr>';
    html += '<td style="background-color: #db4a39; border-top-left-radius: 30px; border-top-right-radius: 30px; color: white; padding-top: 20px; padding-bottom: 20px;" align="center">';

    html += '<span style=" font-size: 24px;">' + date.day_name + ', ' + date.day_number + ' de ' + date.month_name + ' del ' + date.year + '</span>';

    html += '<p>';
    html += '<strong> CLIENTE : </strong> <span>' + login.nameUser + '</span>';
    html += '</p>';

    html += '</td>';
    html += '</tr>';

    html += '<tr>';
    html += '<td style="border-bottom: 1px solid gray; border-left: 1px solid gray; border-right: 1px solid gray; border-bottom-left-radius: 30px; border-bottom-right-radius: 30px; padding: 0px;" align="center">';

    html += '<br>';

    html += '<p style="color: rgb(117, 117, 117);">';
    html += '<h3><span>Número de días</span></h3>';
    html += '</p>';

    html += '<p>';
    html += '<span style="font-size: 50px;">' + reservation.amount + '</span>';
    html += '</p>';

    // Informacion General
    html += '<div style="padding-left:10px; padding-right:10px;">';
    html += '<table style="text-align: center;" width="100%" border="">';

    html += '<tr>';
    html += '<td style="color: rgb(117, 117, 117);">Habitación</td>';
    html += '<td style="color: rgb(117, 117, 117);">Check In</td>';
    html += '<td style="color: rgb(117, 117, 117);">Check Out</td>';
    html += '<td style="color: rgb(117, 117, 117);">Nro. Ref</td>';
    html += '</tr>';

    html += '<tr>';
    html += '<td style="padding-top: 15px; padding-bottom: 15px;">' + room.type + " #" + room.nro + '</td>';
    html += '<td style="padding-top: 15px; padding-bottom: 15px;">' + reservation.check.in + '</td>';
    html += '<td style="padding-top: 15px; padding-bottom: 15px;">' + reservation.check.out + '</td>';
    html += '<td style="padding-top: 15px; padding-bottom: 15px;">' + id_reserva + '</td>';
    html += '</tr>';

    html += '</table>';
    html += '</div>';

    // Informacion por personas
    if (configuracion.habitacion_por_persona == true) {
      html += '<br>';

      html += '<div style="padding-left:10px; padding-right:10px;">';
      html += '<table style="text-align: center;" width="100%" border="">';

      html += '<tr>';
      html += '<td style="color: rgb(117, 117, 117);">Número personas</td>';
      html += '<td style="color: rgb(117, 117, 117);">Precio por persona</td>';
      html += '</tr>';

      html += '<tr>';
      html += '<td style="padding-top: 15px; padding-bottom: 15px;">' + resultReserv.numeroPersonas + '</td>';
      html += '<td style="padding-top: 15px; padding-bottom: 15px;">' + resultReserv.precioPorPersona + '</td>';
      html += '</tr>';

      html += '</table>';
      html += '</div>';
    }

    html += '<br>';

    html += '<div>';
    html += '<table style="text-align: center; font-size:14px" width="100%">';

    html += '<tr>';
    html += '<td><strong>Subtotal: </strong><span> ' + costCalculated.calculations.subtot + '</span></td>';
    html += '</tr>';

    html += '<tr>';
    html += '<td><strong>Descuento: </strong><span> ' + costCalculated.percentage.descount + '</span></td>';
    html += '</tr>';

    if (costCalculated.percentage.surchargeTc > 0) {
      html += '<tr>';
      html += '<td><strong>Recargo: </strong><span> ' + costCalculated.percentage.surchargeTc + '</span></td>';
      html += '</tr>';
    }

    html += '</table>';
    html += '</div>';

    html += '<p><strong> Total: ' + costCalculated.calculations.total + ' </strong></p>';

    if (type == 'normal') {
      html += '<p style="color: white; background-color: gray; font-size: 14px; padding-top: 5px; padding-bottom: 5px;">';
      html += '<strong>Nota: </strong><span>La reserva será pagada el día del hospedaje</span>';
      html += '</p>';
    }

    if (type == 'transfer') {
      html += '<p style="color: white; background-color: gray; font-size: 14px; padding-top: 5px; padding-bottom: 5px;">';
      html += '<strong>Nota: </strong><span>La reserva ha sido realizada por medio de transferencia bancaria </span>';
      html += '</p>';
    }

    if (type == 'paypal') {
      html += '<p style="color: white; background-color: gray; font-size: 14px; padding-top: 5px; padding-bottom: 5px;">';
      html += '<strong>Nota: </strong><span>La reserva ha sido realizada por medio de PayPal</span>';
      html += '</p>';
    }

    html += '<p style="font-size:14px; padding-bottom: 10px;">';
    html += '<span>Para mayor informacion, puedes contactarnos </span>';
    html += '<a href="mailto:' + configuracion.correo_personal_api + '" target="_blank">' + configuracion.correo_personal_api + ' </a>'
    html += '</p>';

    html += '</table>';

    let data = {
      sender_mail: configuracion.correo_api,
      sender_password: configuracion.password_correo_api,
      entity_mail: '',
      receiver: login.email,
      subject: 'Notificación, Reserva de habitación',
      text: '',
      body: html
    }

    return data;

  }

  async sendMailService(data) {
    let url = this.apiLoxafree + "enviarCorreo/send"
    return new Promise((resolve) => {
      this.http.post(url, data).subscribe((res: any) => {
        resolve((res));
      },
        error => {
          resolve(error)
        });
    });
  }

  async createBodyMailRegister(configuracion, data) {

    let html = '<table style="font-family:arial; border:1px solid #e8e6e6; border-top:none; border-bottom:none; border-spacing:0; max-width:600px; color:#707173; border-radius:40px;" align="center">';

    // Imagen de la cabecera
    html += '<thead>';
    html += '<tr>';
    html += '<td style="padding:0">';
    html += '<img style="width:100%; border-radius:20px 20px 0 0" src="https://drive.google.com/uc?export=view&id=1PA4wN0Sxs7vJlmn5zpVwdDlQm-XCwCsz">';
    html += '</td>';
    html += '</tr>';
    html += '</thead>';

    html += '<tbody>';

    // Texto del encabezado
    html += '<tr>';
    html += '<td style="font-size: 18px; text-align: justify; padding:10px 8px 8px 8px; display:block">';
    html += '<span>Bienvenid@, </span>';
    html += '<span><strong style="color: #868686;">' + data.nombres + ' ' + data.apellidos + '</strong>. Gracias por registrarte en nuestro sitio web ' + configuracion.dominioPagina + ', sus credenciales para acceder al sitio son las siguientes:</span>';
    html += '</td>';
    html += '</tr>';

    html += '<tr>';
    html += '<td style="font-size: 18px; text-align: center; padding:10px 5px 8px 5px; display:block">';
    html += '<p style="font-size: 15px;">Cédula / Ruc: ' + data.cedula + ' </p>';
    html += '<p style="font-size: 15px;">Contraseña: ' + data.cedula + ' </p>';
    html += '</td>';
    html += '</tr>';

    html += '</tbody>';
    html += '</table>';

    let dataEmail = {
      sender_mail: configuracion.correo_api,
      sender_password: configuracion.password_correo_api,
      entity_mail: '',
      receiver: data.email,
      subject: 'Registro exitoso',
      text: '',
      body: html
    }

    return dataEmail;
  }

  async createBodyMailRecoverPassword(configuracion, data) {
    let html = '<table style="font-family:arial; border:1px solid #e8e6e6; border-top:none; border-bottom:none; border-spacing:0; max-width:600px; color:#707173; border-radius:40px;" align="center">';

    // Imagen de la cabecera
    html += '<thead>';
    html += '<tr>';
    html += '<td style="padding:0">';
    html += '<img style="width:100%; border-radius:20px 20px 0 0" src="https://drive.google.com/uc?export=view&id=1PA4wN0Sxs7vJlmn5zpVwdDlQm-XCwCsz">';
    html += '</td>';
    html += '</tr>';
    html += '</thead>';

    html += '<tbody>';

    // Texto del encabezado
    html += '<tr>';
    html += '<td style="font-size: 18px; text-align: justify; padding:10px 8px 8px 8px; display:block">';
    html += '<span>Hola, </span>';
    html += '<span><strong style="color: #868686;">' + data.nombres + ' ' + data.apellidos + '</strong>. Su contraseña para acceder al sitio ' + configuracion.dominioPagina + ', se muestra a continuación: </span>';
    html += '</td>';
    html += '</tr>';

    html += '<tr>';
    html += '<td style="font-size: 18px; text-align: center; padding:10px 5px 8px 5px; display:block">';
    html += '<p style="font-size: 15px;">Contraseña: ' + data.cedula + ' </p>';
    html += '</td>';
    html += '</tr>';

    html += '</tbody>';

    html += '</table>';

    let dataEmail = {
      sender_mail: configuracion.correo_api,
      sender_password: configuracion.password_correo_api,
      entity_mail: '',
      receiver: data.email,
      subject: 'Recuperar Contraseña',
      text: '',
      body: html
    }

    return dataEmail;
  }

  async updateConfiguracion(data: any) {
    let url = this.apiLoxafree + "configuracion/" + data.id_configuracion;
    return new Promise((resolve) => {
      this.http.put(url, data)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async updateInformation(information) {
    let url = this.apiLoxafree + "informacions/" + information.id_informacion;
    return new Promise((resolve) => {
      this.http.put(url, information)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async getImagesBanner() {
    let url = this.apiLoxafree + "imagenes-banners/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async insertImageBanner(data) {
    data.id_empresa = this.id_empresa;
    let url = this.apiLoxafree + "imagenes-banners";
    return new Promise((resolve) => {
      this.http.post(url, data)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async deleteImageBanner(id) {
    let url = this.apiLoxafree + "imagenes-banners/" + id;
    return new Promise((resolve) => {
      this.http.delete(url)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async updateAdministrator(data) {
    let url = this.apiLoxafree + "administracions";
    return new Promise((resolve) => {
      this.http.put(url, data)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async insertPromotion(data) {
    data.id_empresa = this.id_empresa;
    let url = this.apiLoxafree + "promociones"
    return new Promise((resolve) => {
      this.http.post(url, data)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async getPromotion() {
    let url = this.apiLoxafree + "promociones/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          let res;
          if (data.length > 0) {
            res = {
              rta: true,
              data: data
            }
          } else {
            res = {
              rta: false,
              data: data
            }
          }
          resolve((res));
        },
          error => {
            resolve(error)
          });
    });
  }

  async deletePromotion(id_promocion) {
    let url = this.apiLoxafree + "promociones/" + id_promocion;
    return new Promise((resolve) => {
      this.http.delete(url)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async getCredentialsDrive() {
    let data = {
      client_id: '',
      api_key: '',
      rta: false
    }
    await this.getConfiguracion().then((resconf: any) => {
      data = {
        client_id: resconf[0].client_id_drive,
        api_key: resconf[0].api_key_drive,
        rta: true
      }
    });
    return data;
  }

  // *** Servicios Billing ***

  async getRoomTypesSerivice(url_billing) {
    let url = url_billing + "tipos_habitacion";
    let typesRoom = [];
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe(async (data: any) => {
          console.log('dataaaaaaa',data);
          if (data.rta) {
            for (let g of data.data) {
              g.view = false;

              if (g.nombre) {
                if (g.nombre.length <= 40) {
                  g.titulo = g.nombre;
                } else {
                  g.titulo = g.nombre.slice(0, 37) + '...';
                }
              }

              if (g.descripcion) {
                if (g.descripcion.length <= 100) {
                  g.desc = g.descripcion;
                  g.vermas = false;
                } else {
                  g.desc = g.descripcion.slice(0, 97) + '...';
                  g.vermas = true;
                }
              } else {
                g.desc = '';
                g.vermas = false;
              }

              if (!g.img) {
                g.img = "1Zs5yattWYEy6vHzScHcr0NuxHA3h7p_I";
              }

              // Mostrar solo los que tienes habitaciones
              await this.getRoomsSerivice(url_billing, g).then((resroom: any) => {
                console.log('RES ROOM ---> ', resroom);
                
                if (resroom.rta == true) {
                  if(resroom.data[0].producto!=null){
                    typesRoom.push(g);

                  }
                }
              });

            }
          }
          let resp = {
            rta: true,
            data: typesRoom
          }
          resolve((resp));
        },
          error => {
            resolve(error)
          });
    });
  }

  async getRoomsSerivice(url_billing, typeRoom) {
    let url = url_billing + "get_habitaciones?tipo=" + typeRoom.id_tiphab;
    console.log('URL', url);
    let arreglo = [];

    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe(async (data: any) => {
          await this.getConfiguracion().then(async (resconf: any) => {
            await this.isAuthenticatedClient(resconf[0].loginStorage).then(async (reslogin: any) => {
              data.data = await this.obtainAndCalculatePriceProduct(data.data, resconf[0], reslogin);
            });
            for (let r of data.data) {
              if(r.producto != null){
                console.log('debe entrar acá', r);
                
            
              r.view = false;
              r.selected = false;
              r.precio = parseFloat(r.precio);
              r.precio_persona = 0;
              await this.getMainImageRoom(r).then((resimg) => {
                r.imagenPrincipal = resimg.imagenPrincipal;
                r.imagenes = resimg.imagenes;
              });
              r.type = typeRoom.nombre;
              if (r.descripcion) {
                if (r.descripcion.length <= 40) {
                  r.desc = r.descripcion;
                  r.vermas = false;
                  r.icn_view = false;
                } else {
                  r.desc = r.descripcion.slice(0, 35) + '...';
                  r.vermas = true;
                  r.icn_view = true;
                }
              } else {
                r.desc = "Para descansar como en casa."
              }
              if (resconf[0].porcentajePrecioOferta > 0) {
                await this.calculatePriceOfert(parseFloat(r.precio), resconf[0].porcentajePrecioOferta).then((resoffer) => {
                  r.offerPrice = resoffer;
                });
              } else {
                r.offerPrice = 0;
              }
              arreglo.push(r);

            }
          }
          
          
          });
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async getRoomReservations(url_billing, data) {
    let url = url_billing + "get_grupos";
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async getMainImageRoom(room) {
    let imagenes = [
      { 'id': 1, 'imagen': room.imagen_uno },
      { 'id': 2, 'imagen': room.imagen_dos },
      { 'id': 3, 'imagen': room.imagen_tres },
    ]
    let aux = false;
    let imagenPrincipal;
    for (let i of imagenes) {
      if (aux == false) {
        if (i.imagen) {
          imagenPrincipal = i.imagen;
          aux = true;
        } else {
          imagenPrincipal = '1p0vP1H04zultmn8C1oI6oeTqkp6IbzSK';
        }
      }
    }
    let data = {
      imagenPrincipal: imagenPrincipal,
      imagenes: imagenes
    }
    return data;
  }

  async getRoomReservationId(url_billing, data) {
    let url = url_billing + "get_reservas_habitacion_mes?habitacion=" + data.id_hab + "&desde=" + data.desde + "&hasta=" + data.hasta;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((data: any) => {
          let response = {
            rta: data.rta,
            data: []
          }
          if (data.rta == true) {
            for (let r in data.data) {
              response.data.push(data.data[r]);
            }
          }
          resolve((response));
        },
          error => {
            resolve(error)
          });
    });
  }

  async registerClientBilling(url_billing, newClient) {
    let url = this.apiLoxafree + "billing/registrarCliente";
    let data = {
      endPoint: url_billing + "registrar_cliente",
      user: {
        cedula: newClient.cedula,
        nombres: newClient.nombres,
        apellidos: newClient.apellidos,
        razonsocial: newClient.razonsocial,
        direccion: newClient.direccion,
        telefonos: newClient.telefonos,
        email: newClient.email,
        celular: newClient.celular,
        tipoCli: newClient.tipoCli,
        vendedor_id: newClient.vendedor_id,
        diasCredito: newClient.diasCredito,
        cupo_credito: newClient.cupo_credito,
        es_pasaporte: newClient.es_pasaporte,
      }
    }
    return new Promise((resolve) => {
      this.http.post(url, data).subscribe((data: any) => {
        resolve(data.respuesta);
      },
        error => {
          resolve(error)
        });
    });
  }

  async validateIdentyDocument(url_billing, ci) {
    let url = url_billing + "validar_cedula?ci=" + ci;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async validateClientCedIdentity(url_billing, validate) {
    let url = url_billing + "cliente_ci?identificacion=" + validate.cedula;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((datares: any) => {
          let data = datares[0];
          // console.log(data);
          let res: any;
          if (data) {
            if (validate.correo == data.email) {
              res = {
                rta: true,
                data: data
              }
            } else {
              res = {
                rta: false,
                data: {}
              }
            }
          } else {
            res = {
              rta: false,
              data: {}
            }
          }
          resolve((res));
        },
          error => {
            resolve(error)
          });
    });
  }

  async updateAttributeTable(url_billing, data) {
    let url = this.apiLoxafree + "billing/update_attribute_table";
    let info = {
      endPoint: url_billing + "update_atributo_tabla",
      data: data
    }

    return new Promise((resolve) => {
      this.http.post(url, info).subscribe((data: any) => {
        resolve(data.respuesta);
      },
        error => {
          resolve(error)
        });
    });
  }

  async createReserva(url_billing, data) {
    let url = this.apiLoxafree + "billing/create_reserva_hotel";
    let info = {
      endPoint: url_billing + "create_reserva_hotel",
      data: data
    }
    return new Promise((resolve) => {
      this.http.post(url, info).subscribe((data: any) => {
        resolve(data.respuesta);
      },
        error => {
          resolve(error)
        });
    });
  }

  // Obtener los datos de cualquier tabla
  async getDataTable(url_billing, data) {
    let url = url_billing + "get_atributo_tabla?tabla=" + data.tabla + "&atributo=" + data.atributo + "&valor_atributo=" + data.valor_atributo + "&filas=" + data.filas;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async getUserCedula(url_billing, cedula) {
    let url = url_billing + "cliente_ci?identificacion=" + cedula;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async getReservationsCedula(url_billing, cedula) {
    let url = url_billing + "get_reservas_client?identificacion=" + cedula;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async cancelReservation(url_billing, id_reserva) {
    let url = url_billing + "cancel_reserva?id_reserva=" + id_reserva;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async getDataCompany(url_billing,) {
    let url = url_billing + "get_empresa";
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async updateLocationCompany(url_billing, latitud, longitud) {
    let url = url_billing + "update_location_empresa?lat=" + latitud + '&lng=' + longitud;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async getPermitRoomForPerson() {
    let data = {
      tabla: 'bill_settings',
      atributo: 'variable',
      valor_atributo: 'HABITACION_POR_PERSONAS',
      filas: '*'
    }
    let response: any;
    await this.getUrlEmpresa().then(async (url_billing) => {
      await this.getDataTable(url_billing, data).then((res: any) => {
        res.data = res.data[0];
        response = res;
      });
    });
    return response;
  }

  // *** Otras funciones ***

  async getCurrentDate() {
    var month_name = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
    var month_number_str = new Array('01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12');
    var month_number = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
    var days = new Array("Domingo", 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado');
    const date = new Date();

    let currentDate = {
      year: date.getFullYear(),
      month_name: month_name[date.getMonth()],
      month_number: month_number_str[date.getMonth()],
      day_number: date.getDate(),
      day_name: days[new Date(date.getFullYear() + '-' + month_number[date.getMonth()] + '-' + date.getDate()).getDay()],
      number_day: new Date(date.getFullYear(), month_number[date.getMonth()], 0).getDate(),
      hour: date.getHours(),
      minute: this.formatHour(date.getMinutes()),
      second: this.formatHour(date.getSeconds()),
    }

    return currentDate;
  }

  // type = next | previous
  async getPreviousNextDate(monthCurrent, type) {

    const date = new Date();
    let year = date.getFullYear();
    let next = parseInt(monthCurrent);

    // if (type == 'previous') {
    //   if (next == 1) {
    //     next = 11;
    //     if (this.newYear != 0) {
    //       if (this.newYear > year) {
    //         this.newYear = this.newYear - 1;
    //       } else {
    //         this.newYear = 0;
    //       }
    //     }
    //   } else {
    //     next = next - 2;
    //   }
    // }

    if (type == 'previous') {
      if (next == 1) {
        next = 11;
        if (this.newYear != 0) {
          if (this.newYear > year) {
            this.newYear = this.newYear - 1;
          } else {
            this.newYear = 0;
          }
        }
      } else {
        if (next >= 2) {
          next = next - 2;
        }
      }
    }

    // if (type == 'next') {
    //   if (next == 12) {
    //     next = 0;
    //     if (this.newYear != 0) {
    //       this.newYear = this.newYear + 1;
    //     } else {
    //       year = year + 1;
    //       this.newYear = year;
    //     }
    //   }
    // }


    if (type == 'next') {
      if (next == 12) {
        next = 0;
      }
      if (next == 0) {
        if (this.newYear == 0) {
          year = year + 1;
          this.newYear = year;
        } else {
          this.newYear = this.newYear + 1;
        }
      }
    }

    if (this.newYear != 0) {
      year = this.newYear;
    }

    // console.log("año calendario", year);

    var month_name = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
    var month_number_str = new Array('01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12');
    var month_number = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
    var days = new Array("Domingo", 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado');

    let netxDate = {
      year: year,
      month_name: month_name[next],
      month_number: month_number_str[next],
      day_number: "01",
      day_name: days[new Date(year + '-' + month_number[next] + '-01').getDay()],
      number_day: new Date(year, month_number[next], 0).getDate(),
      hour: date.getHours(),
      minute: this.formatHour(date.getMinutes()),
      second: this.formatHour(date.getSeconds()),
    }

    return netxDate;
  }

  async getPreviousNextDateButton(monthCurrent, type) {

    const date = new Date();
    let year = date.getFullYear();
    let next = parseInt(monthCurrent);

    if (type == 'previous') {
      if (next == 1) {
        next = 11;
        if (this.newYearBtn != 0) {
          if (this.newYearBtn > year) {
            this.newYearBtn = this.newYearBtn - 1;
          } else {
            this.newYearBtn = 0;
          }
        }
      } else {
        if (next >= 2) {
          next = next - 2;
        }
      }
    }

    if (type == 'next') {
      if (next == 12) {
        next = 0;
      }
      if (next == 0) {
        if (this.newYearBtn == 0) {
          year = year + 1;
          this.newYearBtn = year;
        } else {
          this.newYearBtn = this.newYearBtn + 1;
        }
      }
    }

    if (this.newYearBtn != 0) {
      year = this.newYearBtn;
    }

    var month_name = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
    var month_number_str = new Array('01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12');
    var month_number = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
    var days = new Array("Domingo", 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado');

    // console.log(next + ", " + month_name[next]);

    let netxDate = {
      year: year,
      month_name: month_name[next],
      month_number: month_number_str[next],
      day_number: "01",
      day_name: days[new Date(year + '-' + month_number[next] + '-01').getDay()],
      number_day: new Date(year, month_number[next], 0).getDate(),
      hour: date.getHours(),
      minute: this.formatHour(date.getMinutes()),
      second: this.formatHour(date.getSeconds()),
    }

    return netxDate;
  }

  formatHour(value) {
    if (value < 10) {
      value = 0 + '' + value;
    }
    return value;
  }

  // Guardar la fecha actual del calendario, para generar el siguiente o anterior mes
  async saveCurrenGeneratedCalendar(calendar) {
    this.calendarCurrent = calendar;
  }

  async validateDaySelected(daysAdd, day) {
    let bandera = true;

    for (let push of daysAdd) {
      if (push == day.value) {
        daysAdd = daysAdd.filter(function (i) { return i !== push });
        bandera = false;
      }
    }

    let data = {
      daysAdd: daysAdd,
      day: day,
      bandera: bandera
    }

    return data;
  }

  async getMaxMinDay(days) {
    let data = {
      min: Math.min.apply(Math, days),
      max: Math.max.apply(Math, days)
    }
    return data;
  }

  async createFormatCheck(date, day, daysAdd) {

    let check;

    let min = Math.min.apply(Math, daysAdd);
    let max = Math.max.apply(Math, daysAdd);

    check = {
      in: date.year + '-' + date.month_number + '-' + min,
      out: date.year + '-' + date.month_number + '-' + max,
      hourIn: date.hour + ':' + date.minute + ':' + date.second,
      hourOut: date.hour + ':' + date.minute + ':' + date.second,
    }

    return check;
  }

  async getCheckInOutGeneral() {

    let data;
    var month_number_str = new Array('01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12');

    if (this.checkRoom.length > 0) {

      if (this.checkRoom.length == 1) {
        data = {
          in: this.checkRoom[0].check.in,
          out: this.checkRoom[0].check.out,
          hourIn: this.checkRoom[0].check.hourIn,
          hourOut: this.checkRoom[0].check.hourOut,
        }
      } else {

        let chekIn = [];
        let chekOut = [];

        for (let c of this.checkRoom) {

          let divMin = c.check.in.split("-");
          let divMax = c.check.out.split("-");

          let i = divMin[0] + '-' + divMin[1] + '-"' + divMin[2] + '"';
          let o = divMax[0] + '-' + divMax[1] + '-"' + divMax[2] + '"';

          // let i = c.check.in;
          // let o = c.check.out;
          chekIn.push(new Date(i));
          chekOut.push(new Date(o));

        }

        let min = new Date(Math.min.apply(null, chekIn));
        let max = new Date(Math.max.apply(null, chekOut));

        let dayMin;
        let dayMax;

        if (min.getDate() < 10) {
          dayMin = '0' + min.getDate();
        } else {
          dayMin = min.getDate();
        }

        if (max.getDate() < 10) {
          dayMax = '0' + max.getDate();
        } else {
          dayMax = max.getDate();
        }

        data = {
          in: min.getFullYear() + '-' + month_number_str[min.getMonth()] + '-' + dayMin,
          out: max.getFullYear() + '-' + month_number_str[max.getMonth()] + '-' + dayMax,
          hourIn: this.checkRoom[0].check.hourIn,
          hourOut: this.checkRoom[0].check.hourOut,
        }

      }

      var firstDate = moment(data.in);
      var secondDate = moment(data.out);
      var diffDays = secondDate.diff(firstDate, 'days') + 1;

      data.daysNum = diffDays;

    }

    return data;
  }

  // Guardar los datos del check para controlar si estan en el mismo mes
  async saveDateCheckRoom(dateSelected, day) {
    let data;
    if (this.checkRoom.length > 0) {
      let exist = false;
      for (let r of this.checkRoom) {
        if (r.dateSelected.month_number == dateSelected.month_number && r.dateSelected.year == dateSelected.year) {
          exist = true;
          let bandera = true;
          r.days = r.days;
          for (let v of r.days) {
            if (v == day.value) {
              r.days = r.days.filter(function (i) { return i !== v });
              bandera = false;
            }
          }
          if (bandera == true) {
            r.days.push(day.value);
            await this.createFormatCheck(dateSelected, day, r.days).then(async (resck) => {
              data = {
                dateSelected: dateSelected,
                days: r.days,
                check: resck
              }
              r.check.in = resck.in;
              r.check.out = resck.out;
              r.check.hourIn = resck.hourIn;
              r.check.hourOut = resck.hourOut;
            });
          } else {
            await this.createFormatCheck(dateSelected, day, r.days).then(async (resck) => {
              data = {
                dateSelected: dateSelected,
                days: r.days,
                check: resck
              }
              r.check.in = resck.in;
              r.check.out = resck.out;
              r.check.hourIn = resck.hourIn;
              r.check.hourOut = resck.hourOut;
            });
          }
        }
      }
      if (exist == false) {
        await this.createFormatCheck(dateSelected, day, [day.value]).then(async (resck) => {
          data = {
            dateSelected: dateSelected,
            days: [day.value],
            check: resck
          }
          this.checkRoom.push(data);
        });
      }

    } else {
      await this.createFormatCheck(dateSelected, day, [day.value]).then(async (resck) => {
        data = {
          dateSelected: dateSelected,
          days: [day.value],
          check: resck
        }
        this.checkRoom.push(data);
      });
    }

    if (data.days.length == 0) {
      for (let r of this.checkRoom) {
        if (r.dateSelected.month_number == dateSelected.month_number && r.dateSelected.year == dateSelected.year) {
          // console.log("**** eliminar del arreglo ****", r);
          this.checkRoom = this.checkRoom.filter(function (i) { return i !== r });
        }
      }
    }

    return data;
  }

  async getDateCheckRoom() {
    return this.checkRoom;
  }

  // Restablecer valor de variables del servicio, se envia como parametro el nombre de la variable
  async resetVariables(variable) {
    if (variable == "newYear") {
      this.newYear = 0;
    }

    if (variable == "checkRoom") {
      this.checkRoom = [];
    }
  }

  async getCurrenGeneratedCalendar() {
    return this.calendarCurrent;
  }

  async getUrlDrive() {
    let urlDrive = "https://drive.google.com/uc?export=view&id=";
    let defaultBanner = '../../../assets/img/banner-default.jpeg';

    let data = {
      urlDrive: urlDrive,
      defaultBanner: defaultBanner
    }
    return data;
  }

  async saveGroupRoomSelected(rooms) {
    this.roomsSelected = rooms;
  }

  async getGroupRoomSelected() {
    let res;
    if (this.roomsSelected) {
      res = {
        rta: true,
        data: this.roomsSelected
      }
    } else {
      res = {
        rta: false,
        rooms: this.roomsSelected
      }
    }

    return res;

  }

  validateEmail(email) {
    let expr = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;
    if (expr.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  convertMayuscula(string) {
    let cadena = string.toUpperCase();
    return cadena;
  }

  async calculatePriceOfert(price, porcent) {
    let porcentVal = (price * porcent) / 100;
    let val = price + porcentVal;
    return val;
  }

  async obtainAndCalculatePriceProduct(products, config, login) {
    // console.log('productos', products);
    // console.log('config', config);
    // console.log('login', login);
    let tipoPrecio;
    if (products.length > 0) {
      if (login.rta == true) {
        if (login.data.rol == 'Client') {
          tipoPrecio = login.data.default_price;
        } else {
          tipoPrecio = config.tipoPrecio;
        }
      } else {
        tipoPrecio = config.tipoPrecio;
      }
      for (let prod of products) {
        // console.log('ENTRA HASTA ACA', tipoPrecio);
        
        let aux = {
          bandera: false,
          tipo: 'pA',
          valor: '',
        }

        if(prod.producto!= null){
          console.log('ENTRA 6 veces', prod);
          for (let p of prod.producto.precios) {
            if (p.id_tipo == tipoPrecio) {
              aux = {
                bandera: true,
                tipo: p.id_tipo,
                valor: p.valor_mas_iva
              }
            } else {
              if (aux.bandera == false) {
                aux = {
                  bandera: false,
                  tipo: p.id_tipo,
                  valor: p.valor_mas_iva
                }
              }
            }
          }
        }
        //   for (let p of prod.producto.precios) {
        //     if (p.id_tipo == tipoPrecio) {
        //       aux = {
        //         bandera: true,
        //         tipo: p.id_tipo,
        //         valor: p.valor_mas_iva
        //       }
        //     } else {
        //       if (aux.bandera == false) {
        //         aux = {
        //           bandera: false,
        //           tipo: p.id_tipo,
        //           valor: p.valor_mas_iva
        //         }
        //       }
        //     }
        //   }

        if (aux.bandera == true) {
          prod.tipoPrecio = aux.tipo;
          prod.precio = parseFloat(parseFloat(aux.valor).toFixed(2));
        } else {
          prod.tipoPrecio = aux.tipo;
          prod.precio = parseFloat(parseFloat(aux.valor).toFixed(2));
        }

      }
    }
    return products;
  }

  async calculateReservationCost(room, reserve, configuration, type) {

    let data: any;
    let percentageDescount = parseFloat(configuration.porcentajeDescuento);
    let percentageSurchargeTc = parseFloat(configuration.porcentajeCompraTarjeta);

    let amount = reserve.amount;
    let subtot = amount * room.precio;
    let descount = (subtot * percentageDescount) / 100;
    let subtotDescount = subtot - descount;
    let typeTransaction;
    let surchargeTc = 0;
    let total;

    if (type == 'normal' || type == 'transfer') {
      total = subtotDescount;
      typeTransaction = 0;
    }

    if (type == 'paypal') {
      surchargeTc = (subtotDescount * percentageSurchargeTc) / 100;
      total = subtotDescount + surchargeTc;
      typeTransaction = 1;

    }

    data = {
      percentage: {
        descount: descount,
        surchargeTc: surchargeTc
      },
      calculations: {
        typeTransaction: typeTransaction,
        amount: amount,
        subtot: subtot,
        subtotDescount: subtotDescount,
        total: total
      }
    }

    return data;

  }

  getDataPaypal(room, reservation, costCalculated) {
    let items = [];

    let detail = {
      name: room.type + ' # ' + room.nro,
      quantity: 1,
      unit_amount: {
        currency_code: 'USD',
        value: costCalculated.calculations.subtotDescount,
      }
    }

    items.push(detail);

    if (costCalculated.percentage.surchargeTc > 0) {
      costCalculated.percentage.surchargeTc = parseFloat((costCalculated.percentage.surchargeTc).toFixed(2));
      let data = {
        name: "Credit card surcharge",
        quantity: 1,
        unit_amount: {
          currency_code: 'USD',
          value: costCalculated.percentage.surchargeTc,
        }
      }
      items.push(data);
    }


    return items;

  }

  // Login Cliente o Administrador

  async loginClient(url_billing, data) {
    let url = url_billing + "login_web_cliente?usuario=" + data.usuario + "&clave=" + data.clave;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve((data.query[0]));
        },
          error => {
            resolve(error)
          });
    });
  }

  async signOuth(loginStorage) {
    return new Promise(async (resolve) => {
      this.localStorage.removeItem(loginStorage).subscribe((remov) => {
        let data = {
          rta: true,
          data: remov
        }

        this.clientLogin = {
          name: '',
          imagen: '',
          login: false,
          rol: ''
        }
        this.usr$.next(this.clientLogin);

        resolve(data);
      });
    });
  }

  async loginAdministrator(login) {
    let url = this.apiLoxafree + "administracions/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          let res: any;
          if (data.length > 0) {
            if (data[0].usuario == login.usuario && data[0].password == login.clave) {
              res = {
                rta: true,
                data: data[0]
              }
            } else {
              res = {
                rta: false,
                data: {}
              }
            }
          } else {
            res = {
              rta: false,
              data: {}
            }
          }
          resolve((res));
        },
          error => {
            resolve(error)
          });
    })
  }

  async saveClientLocalStorage(user, loginStorage, rol) {
    let nameAll = user.nombres + ' ' + user.apellidos;
    user.rol = rol;
    if (nameAll) {
      if (nameAll.length <= 30) {
        user.nameUser = nameAll;
      } else {
        user.nameUser = nameAll.slice(0, 30);
      }
    } else {
      user.nameUser = 'DEFAULT NAME';
    }

    return new Promise(async (resolve) => {
      await this.localStorage.setItem(loginStorage, user).subscribe(async (auth) => {
        let data = {
          rta: auth,
          data: user,
        }
        this.clientLogin = {
          name: user.nameUser,
          imagen: user.imagen,
          login: true,
          rol: user.rol
        }
        this.usr$.next(this.clientLogin);
        resolve(data);
      }, error => {
        let data = {
          rta: false,
          data: error,
        }
        resolve(data);
      });
    });
  }

  observableLoginUser(): Observable<userNames> {
    return this.usr$.asObservable();
  }

  async isAuthenticatedClient(loginStorage) {
    return new Promise((resolve) => {
      this.localStorage.getItem(loginStorage).subscribe((auth: any) => {
        let data;
        if (auth) {
          data = {
            rta: true,
            data: auth
          }
        } else {
          data = {
            rta: false,
            data: auth
          }
        }
        resolve(data);
      });
    });
  }

  async goHome() {
    this.router.navigateByUrl('home');
  }

  async goUserProfile() {
    this.router.navigateByUrl("user");
  }

  async goAdminProfile() {
    this.router.navigateByUrl("admin");
  }

  async goToAboutUs() {
    this.router.navigateByUrl('about-us');
  }

  async goPromotions() {
    this.router.navigateByUrl('promotions');
  }

  async orderObjectsAsc(data, type) {

    if (type == 'reservas') {
      // Ordenar alfabeticamente
      let order = data.sort(function (a, b) {
        if (a.fecha_checkin < b.fecha_checkin)
          return -1;
        if (a.fecha_checkin > b.fecha_checkin)
          return 1;
        return 0;
      });

      return order;

    }


  }

  async deletePromotionWeb(id_promocion) {
    let url = this.apiLoxafree + "promociones/" + id_promocion;
    return new Promise((resolve) => {
      this.http.delete(url)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  async getPromocionesWeb() {
    let url = this.apiLoxafree + "promociones/" + this.id_empresa;
    return new Promise((resolve) => {
      this.http.get(url)
        .subscribe((data: any) => {
          // console.log('promociones', data);
          resolve(data);
        },
          error => {
            resolve(error)
          });
    });
  }

  
  async getProductosCodigoService(url_billing, id_producto, configuracion) {
    let url = url_billing + 'get_product_id?codigo=' + id_producto + '&bodega_id=0';
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe(async (data: any) => {
          let resp = {
            rta: false,
            data: []
          };
          if (data.rta == true) {
            // await this.addAtributesProducts([data.data], url_billing, configuracion).then((resprod) => {
            //   resp = {
            //     rta: resprod.rta,
            //     data: resprod.data
            //   }
            // });
          }
          resolve((resp));
        },
          error => {
            resolve(error)
          });
    });
  }

  async insertPromotionWeb(data: any) {
    data.id_empresa = this.id_empresa;
    let url = this.apiLoxafree + "promociones"
    return new Promise((resolve) => {
      this.http.post(url, data)
        .subscribe((data: any) => {
          resolve((data));
        },
          error => {
            resolve(error)
          });
    });
  }

  // -------------------------------- NUEVO POST =================================
  postGeneral2(url: any, json: any) {
    console.log('url ==> ', url);
    console.log('json ==> ', url);
    
    return this.http.post(url, json);
  }

}


