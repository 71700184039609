<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }" [template]="">
</ngx-loading>


<section>

    <div class="container-fluid">
        <div class="row d-flex d-md-block flex-nowrap wrapper">

            <div class="col-lg-2 float-left col-1 pl-0 pr-0 collapse width show" id="sidebar">
                <div class="list-group border-0 text-center text-md-left">

                    <a class="list-group-item d-inline-block collapsed tipo-cursor opc-menu" (click)="goHome()">
                        <i class="fas fa-house-user"></i>
                        <span class="d-none d-md-inline">Inicio</span>
                    </a>

                    <hr>

                    <a (click)="selectMenu('datosGenerales')"
                        class="list-group-item d-inline-block collapsed tipo-cursor opc-menu">
                        <i class="fas fa-cogs"></i>
                        <span class="d-none d-md-inline">Datos Generales</span>
                    </a>

                    <a (click)="selectMenu('sobreNosotros')"
                        class="list-group-item d-inline-block collapsed tipo-cursor opc-menu">
                        <i class="fas fa-info-circle"></i>
                        <span class="d-none d-md-inline">Sobre Nosotros</span>
                    </a>

                    <a (click)="selectMenu('descuentos')"
                        class="list-group-item d-inline-block collapsed tipo-cursor opc-menu">
                        <i class="fas fa-comment-dollar"></i>
                        <span class="d-none d-md-inline">Configuraciones</span>
                    </a>

                    <a (click)="selectMenu('promociones')"
                        class="list-group-item d-inline-block collapsed tipo-cursor opc-menu">
                        <i class="fab fa-buffer"></i>
                        <span class="d-none d-md-inline">Promociones</span>
                    </a>

                    <a (click)="selectMenu('imagenesBanner')"
                        class="list-group-item d-inline-block collapsed tipo-cursor opc-menu">
                        <i class="far fa-images"></i>
                        <span class="d-none d-md-inline">Imagen Banner</span>
                    </a>

                    <a href="#menu3" class="list-group-item d-inline-block collapsed sin-subrayado opc-menu"
                        data-toggle="collapse" aria-expanded="false" (click)="selectMenu('imagenesHabitaciones')">
                        <i class="far fa-image"></i>
                        <span class="d-none d-md-inline">Imagenes Hab.</span>
                    </a>

                    <div class="collapse" id="menu3">

                        <a class="list-group-item ml-2 tipo-cursor opc-menu" (click)="viewTypesRooms('Types')">
                            <i class="fas fa-layer-group ml-2"></i>
                            <span>Tipos Habitaciones</span>
                        </a>

                        <a class="list-group-item ml-2 tipo-cursor opc-menu" (click)="viewTypesRooms('Rooms')">
                            <i class="fas fa-grip-horizontal ml-2"></i>
                            <span>Habitaciones</span>
                        </a>

                    </div>

                    <a (click)="selectMenu('credenciales')"
                        class="list-group-item d-inline-block collapsed tipo-cursor opc-menu">
                        <i class="fas fa-key"></i>
                        <span class="d-none d-md-inline">Credenciales</span>
                    </a>

                    <hr>

                    <a class="list-group-item d-inline-block collapsed tipo-cursor opc-menu" (click)="goHome()">
                        <i class="fas fa-arrow-circle-left"></i>
                        <span class="d-none d-md-inline">Regresar</span>
                    </a>

                    <a class="list-group-item d-inline-block collapsed tipo-cursor opc-menu" (click)="signOff()">
                        <i class="fas fa-lock text-danger"></i>
                        <span class="d-none d-md-inline">Cerrar Sesión</span>
                    </a>

                </div>
            </div>

            <main class="col-lg-10 float-left pl-5 w-100">

                <br>

                <div class="container" *ngIf="menu.datosGenerales">

                    <!-- Logo, Color de fondo y Letra -->
                    <div class="row form-group text-center">

                        <div class="col-lg-4 text-size-14">
                            <label class="col-form-label">Logo Actual </label>
                            <img class="ml-3" [src]="urlBase+configuracion?.imgLogo+'?alt=media'"
                            style="width: 75px; object-fit: scale-down;">
                            <i class="fas fa-edit ml-4" title="Editar Imagen sobre nosotros"
                                (click)="ModalUploadFile(uploadFileModal, 'Logo', '')"></i>
                            <br>
                            <span class="text-danger tipo-letra text-size-12">( Tamaño recomendado 160 * 50 px)</span>
                        </div>

                        <!-- #fbc4a8 -->
                        <div class="col-lg-3 text-size-14">
                            <label class="col-form-label">Seleccione el color de fondo </label>
                            <input type="color" name="colorPrincipal" class="ml-3"
                                [(ngModel)]="configuracion.colorPrincipal">
                        </div>

                        <!-- #7F4626 -->
                        <div class="col-lg-3 text-size-14">
                            <label class="col-form-label">Seleccione el color del texto </label>
                            <input type="color" name="colorLetra" class="ml-3" [(ngModel)]="configuracion.colorLetra">
                        </div>

                        <div class="col-lg-2 text-center">
                            <button class="btn btn-outline-dark"
                                (click)="updateColors(configuracion.colorPrincipal, configuracion.colorLetra)">
                                <i class="far fa-save"></i>
                            </button>
                        </div>

                    </div>
                    <hr>

                    <div class="form-group text-size-14">
                        <label for="nombre">Nombre</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="information.nombre">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="direccion">Dirección</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="information.direccion">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="email">Email</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="information.email">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="telefonos">Teléfonos</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="information.telefonos">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="whatsapp">WhatsApp</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" placeholder="593912345678" [(ngModel)]="information.whatsapp">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="blog">Blog</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="information.blog">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="facebook">Facebook</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="information.facebook">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="instagram">Instagram</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="information.instagram">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="youtube">YouTube</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="information.youtube">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="twitter">Twitter</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="information.twitter">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="tiktok">TikTok</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="information.tiktok">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="messenger">Messenger</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="information.messenger">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="banco">Cuenta Bancaria</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" placeholder="Número de cuenta" [(ngModel)]="information.numeroCuenta">
                        <br>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" placeholder="Titular de la cuenta" [(ngModel)]="information.nombreCuenta">
                        <br>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" placeholder="Entidad bancaria" [(ngModel)]="information.nombreBanco">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="messenger">Video Promocional</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="information.video">
                    </div>

                    <div class="form-group text-size-14">
                        <div class="row form-group">
                            <div class="col-lg-6">
                                <label for="messenger">Latitud</label>
                                 <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" [(ngModel)]="location.latitud" (input)="editLocation($event)">
                            </div>
                            <div class="col-lg-6">
                                <label for="messenger">Longitud</label>
                                <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" [(ngModel)]="location.longitud" (input)="editLocation($event)">
                            </div>
                        </div>
                    </div>

                    <br>

                    <div class="form-group text-center w-100">
                        <button type="button" class="btn btn-dark" (click)="updateInformation(information)">
                            <i class="far fa-save"></i>
                        </button>
                    </div>

                    <br>

                </div>

                <div class="container" *ngIf="menu.sobreNosotros">

                    <div class="form-group text-center">

                        <div class="row text-center">

                            <div class="col-lg-9 text-size-14 text-left">

                                <label for="historia">Historia</label>

                                <div class="row">

                                    <div class="col-12">
                                        <textarea style="font-size: 13px;"
                                            class="form-control form-control-sm text-normal" rows="3"
                                            [(ngModel)]="information.historia"></textarea>
                                    </div>

                                </div>

                            </div>

                            <div class="col-lg-3">
                                <img class="img-info mt-1" *ngIf="information.imagenHistoria"
                                [src]="urlBase+information.imagenHistoria+'?alt=media'">
                                <img class="img-info mt-1" *ngIf="!information.imagenHistoria"
                                    src="../../../../assets/img/iconos-mision-01.png">
                                    <!-- <img  class="img-info mt-1" [src]="urlBase+information.imagenHistoria+'?alt=media'"> -->
                                <i class="fas fa-edit ml-2" title="Editar Imagen sobre nosotros"
                                    (click)="ModalUploadFile(uploadFileModal, 'AboutUs', 'history')"></i>
                                <br>
                                <span class="text-danger tipo-letra text-size-12">( Tamaño recomendado 512 * 512
                                    px)</span>
                            </div>

                        </div>
                        <hr>

                        <div class="row text-center">

                            <div class="col-lg-9 text-size-14 text-left">

                                <label for="historia">Misión</label>

                                <div class="row">

                                    <div class="col-12">
                                        <textarea style="font-size: 13px;"
                                            class="form-control form-control-sm text-normal" rows="3"
                                            [(ngModel)]="information.mision"></textarea>
                                    </div>

                                </div>

                            </div>

                            <!-- <div class="col-lg-3">
                                <img class="img-info mt-1" *ngIf="information.imagenMision"
                                    src="https://drive.google.com/uc?export=view&id={{information.imagenMision}}">
                                <img class="img-info mt-1" *ngIf="!information.imagenMision"
                                    src="https://drive.google.com/uc?export=view&id=1xqdJ1rLDBOtza-N3MapRAeGHVOS_8UZr">
                                <i class="fas fa-edit ml-2"
                                    (click)="ModalUploadFile(uploadFileModal, 'AboutUs', 'mision')"></i>
                                <br>
                                <span class="text-danger tipo-letra text-size-12">( Tamaño recomendado 512 * 512
                                    px)</span>
                            </div> -->

                        </div>
                        <hr>

                        <div class="row text-center">

                            <div class="col-lg-9 text-size-14 text-left">

                                <label for="historia">Visión</label>

                                <div class="row">

                                    <div class="col-12">
                                        <textarea style="font-size: 13px;"
                                            class="form-control form-control-sm text-normal" rows="3"
                                            [(ngModel)]="information.vision"></textarea>
                                    </div>

                                </div>

                            </div>

                            <!-- <div class="col-lg-3">
                                <img class="img-info mt-1" *ngIf="information.imagenVision"
                                    src="https://drive.google.com/uc?export=view&id={{information.imagenVision}}">
                                <img class="img-info mt-1" *ngIf="!information.imagenVision"
                                    src="https://drive.google.com/uc?export=view&id=1xqdJ1rLDBOtza-N3MapRAeGHVOS_8UZr">
                                <i class="fas fa-edit ml-2"
                                    (click)="ModalUploadFile(uploadFileModal, 'AboutUs', 'vision')"></i>
                                <br>
                                <span class="text-danger tipo-letra text-size-12">( Tamaño recomendado 512 * 512
                                    px)</span>
                            </div> -->

                        </div>
                        <hr>

                        <div class="row text-center">

                            <div class="col-lg-9 text-size-14 text-left">

                                <label for="historia">Valores</label>

                                <div class="row">

                                    <div class="col-12">
                                        <textarea style="font-size: 13px;"
                                            class="form-control form-control-sm text-normal" rows="3"
                                            [(ngModel)]="information.valores"></textarea>
                                    </div>

                                </div>

                            </div>

                            <!-- <div class="col-lg-3">
                                <img class="img-info mt-1" *ngIf="information.imagenValores"
                                    src="https://drive.google.com/uc?export=view&id={{information.imagenValores}}">
                                <img class="img-info mt-1" *ngIf="!information.imagenValores"
                                    src="https://drive.google.com/uc?export=view&id=1xqdJ1rLDBOtza-N3MapRAeGHVOS_8UZr">
                                <i class="fas fa-edit ml-2"
                                    (click)="ModalUploadFile(uploadFileModal, 'AboutUs', 'valores')"></i>
                                <br>
                                <span class="text-danger tipo-letra text-size-12">( Tamaño recomendado 512 * 512
                                    px)</span>
                            </div> -->

                        </div>

                        <br>

                        <div class="form-group text-center w-100">
                            <button type="button text-center" class="btn btn-dark"
                                (click)="updateAboutUs(information)">
                                <i class="far fa-save"></i>
                            </button>
                        </div>

                        <br>

                    </div>

                </div>

                <div class="container" *ngIf="menu.descuentos">

                    <div class="form-group text-size-14">

                        <p class="text-center"><strong>Tarjeta de crédito</strong></p>

                        <div class="row form-group">

                            <div class="col-lg-8">
                                <label>Descripción del recargo</label>
                                <input style="font-size: 13px;" class="form-control form-control-sm text-normal"
                                    type="text" name="" id="" [(ngModel)]="configuracion.txtRecargoTarjetaC">
                            </div>

                            <div class="col-lg-4">
                                <label>Porcentaje del recargo</label>
                                <input style="font-size: 13px;" class="form-control form-control-sm text-normal"
                                    type="text" name="" id="" [(ngModel)]="configuracion.porcentajeCompraTarjeta"
                                    placeholder=" 0">
                            </div>

                        </div>
                    </div>

                    <div class="form-group text-size-14">
                        <label for="nombre">Porcentaje Descuento por promoción (Ejm: <strong>Black Friday</strong> )
                        </label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="configuracion.porcentajeDescuento">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="nombre">Porcentaje precio oferta</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="configuracion.porcentajePrecioOferta">
                    </div>

                    <br>

                    <div class="form-group text-size-14">
                        <p class="text-center"><strong>Boton forma de pago</strong></p>
                        <label for="nombre">Texto del boton</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text" name="" id="" [(ngModel)]="configuracion.txtBtnEnviarPedido1">
                    </div>
                    
                    <br>
                    <p class="text-center text-size-14"><strong>Sección Habitaciones</strong></p>

                    <div class="form-group text-size-14" *ngIf="room_for_person == true">
                        <label for="nombre">Reservas de Habitación por persona</label>
                        <select class="form-control form-control-sm text-normal w-25" (change)="updateRoomForPerson($event.target.value, configuracion)" [(ngModel)]="configuracion.habitacion_por_persona" title="Habilitar / Deshabilitar Habitación por persona">
                            <option value="1">Si</option>
                            <option value="0">No</option>
                        </select>
                    </div>

                    <div class="form-group text-size-14">
                        <label for="nombre">Titulo</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="configuracion.tokenPayphone">
                    </div>

                    <div class="form-group text-size-14">
                        <label for="nombre">Descripción</label>
                        <textarea style="font-size: 13px;" class="form-control form-control-sm text-normal" rows="3"
                            [(ngModel)]="configuracion.storeId"></textarea>
                    </div>

                    <br>

                    <div class="form-group text-center w-100">
                        <button type="button text-center" class="btn btn-dark"
                            (click)="updateConfiguration(configuracion)">
                            <i class="far fa-save"></i>
                        </button>
                    </div>

                    <br>

                </div>

                <div class="container" *ngIf="menu.promociones">

                    <div class="row">

                        <div class="col-lg-6">
                            <div class="form-group text-size-14">
                                <label for="nombre">Titulo *</label>
                                <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                                    name="" id="" [(ngModel)]="newPromotion.title">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group text-size-14">
                                <label for="nombre">Descripción *</label>
                                <textarea style="font-size: 13px;" class="form-control form-control-sm text-normal" rows="3"
                                    [(ngModel)]="newPromotion.description"></textarea>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="form-group text-size-14">
                                <label class="col-form-label"> <strong class="text-size-13">Imagen *</strong></label>
                                <p class="text-danger text-size-12">El tamaño recomendado de la imagen debe ser de 800 x 400</p>
                                <input style="font-size: 12px;" class="form-control form-control-sm" #imagePromotion type="file" accept="image/*">
                            </div>
                        </div>

                        <div class="col-3">
                            <div class="form-group text-size-14">
                                <label for="nombre" style="visibility: hidden;">Tipo de habitación</label>
                                <p for="nombre">Tipo de habitación</p>
                                <p class="text-center">
                                    <select class="form-control form-control-sm text-normal"
                                        (change)="selectRoomTypePromotion($event)">
                                        <option value="" selected disabled>Seleccione una opción</option>
                                        <option value="{{t.id_tiphab}}" *ngFor="let t of typesRooms">{{t.nombre}}
                                        </option>
                                    </select>
                                </p>
                            </div>
                        </div>

                        <div class="col-3" *ngIf="newPromotion.viewRoom">
                            <div class="form-group text-size-14">
                                <label for="nombre" style="visibility: hidden;">Habitación</label>
                                <p for="nombre">Habitación</p>
                                <p class="text-center">
                                    <select class="form-control form-control-sm text-normal" (change)="selectRoomPromotion($event)">
                                        <option value="" selected disabled>Seleccione</option>
                                        <option value="{{r.id_hab}}" *ngFor="let r of rooms">{{r.nro}}</option>
                                    </select>
                                </p>
                            </div>
                        </div>

                    </div>

                    <br>

                    <div class="form-group text-center w-100">
                        <button type="button" class="btn btn-dark" (click)="createPromotion(imagePromotion, newPromotion)">
                            <i class="far fa-save"></i>
                        </button>
                    </div>

                    <br>

                    <div class="row form-group">

                        <div class="col-lg-12 table-responsive">
                            <table class="table table-hover text-size-13">
                                <thead>
                                    <tr class="text-center">
                                        <th scope="col">Titulo</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">id_producto</th>
                                        <th scope="col">imagen</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of promotions" class="text-size-13 text-normal text-center">
                                        <td>{{p.nombrePromocion}}</td>
                                        <td>{{p.descripcion}}</td>
                                        <td>{{p.id_producto}}</td>
                                        <td>
                                            <img [src]="urlBase+p.imgPromocion+'?alt=media' " class="mr-2"
                                                style="max-width: 150px">
                                            <!-- <img src="https://drive.google.com/uc?export=view&id={{p.imgPromocion}}" class="mr-2"
                                                style="max-width: 150px"> -->
                                            
                                            </td>
                                        <td (click)="ModaldeleteFile(deleteFileModal, p, 'Promotion')"><i
                                                class="far fa-trash-alt text-danger text-size-16"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <div class="container" *ngIf="menu.imagenesBanner">

                    <div class="row form-group">
                        <div class="row border rounded mt-2 ml-0 mr-0 bg-light">
                            <div class="col-lg-12 text-center mb-2">
                              <label class="col-form-label"> <strong class="text-size-13">Nuevo Banner</strong></label>
                              <p class="text-danger text-size-12">El tamaño recomendado de la imagen debe ser de 950 * 500 px</p>
                              <button class="btn btn-outline-dark" (click)="ModalUploadFile(uploadFileModal, 'Banner', '')">
                                <i class="fas fa-plus-circle"></i>
                              </button>
                            </div>
                          </div>
<!-- 
                        <div class="col-lg-1"></div>

                        <div class="col-lg-8 text-center">
                            <label class="col-form-label"> <strong class="text-size-13">Nuevo Banner</strong></label>
                            <p class="text-danger text-size-12">El tamaño recomendado de la imagen debe ser de 1200 x
                                550</p>
                            <input style="font-size: 12px;" class="form-control form-control-sm" #imageBanner
                                type="file" accept="image/*">
                        </div>

                        <div class="col-lg-2 text-center">
                            <br>
                            <button class="btn btn-outline-dark" (click)="ModalUploadFile(imageBanner, 'Banner', '')">
                                <i class="far fa-save"></i>
                            </button>
                        </div>

                        <div class="col-lg-1"></div> -->

                    </div>

                    <br>

                    <div class="row form-group">

                        <div class="col-lg-12 table-responsive">
                            <table class="table table-hover text-size-13">
                                <thead>
                                    <tr class="text-center">
                                        <th scope="col">id</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Imagen</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let b of imagesBanner" class="text-size-13 text-normal text-center">
                                        <th scope="row">{{b.id_img_banner}}</th>
                                        <td>{{b.nombre}}</td>
                                        <td>
                                            <img [src]="urlBase+b.url+'?alt=media'" class="mr-2" style="max-width: 150px">

                                            <!-- <img src="https://drive.google.com/uc?export=view&id={{b.url}}" class="mr-2"
                                                style="max-width: 150px"> -->
                                            </td>
                                        <td (click)="ModaldeleteFile(deleteFileModal, b, 'Banner')"><i
                                                class="far fa-trash-alt text-danger text-size-16"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <div class="container" *ngIf="menu.imagenesHabitaciones">

                    <div class="row form-group text-size-14" *ngIf="viewRooms.types">

                        <div class="col-lg-12 text-center table-responsive">

                            <p><strong>Tipos de Habitaciones</strong></p>
                            <p class="text-danger text-size-12">El tamaño recomendado de la imagen debe ser de 600 x 450
                            </p>
                            <table class="table table-hover text-size-13">
                                <thead>
                                    <tr class="text-center">
                                        <th scope="col">id</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Imagen</th>
                                        <th scope="col">Descripcion</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let t of typesRooms" class="text-size-13 text-normal text-center">
                                        <th scope="row">{{t.id_tiphab}}</th>
                                        <td>{{t.nombre}}</td>
                                        <td>
                                            <!-- <img src="https://drive.google.com/uc?export=view&id={{t.img}}" style="max-width: 150px"> -->
                                            <img *ngIf="t.img != '1Zs5yattWYEy6vHzScHcr0NuxHA3h7p_I'"  [src]="urlBase+t.img+'?alt=media'" style="max-width: 75px">
                                            <img  *ngIf="t.img == '1Zs5yattWYEy6vHzScHcr0NuxHA3h7p_I'" src="../../../assets/img/generica.jpg" style="max-width: 75px">
                                            <i class="fas fa-edit text-size-16 mt-2 ml-2" (click)="ModalUploadFile(uploadFileModal, 'TypesRooms', t)"></i>
                                        </td>
                                        <td>
                                            <textarea style="font-size: 13px;" class="form-control form-control-sm text-normal" rows="3" [(ngModel)]="t.descripcion"></textarea>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-dark btn-sm" (click)="updateDescriptionTypeRoom(t)">
                                                <i class="far fa-save"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>

                    <div class="row form-group text-size-14 text-center" *ngIf="viewRooms.rooms">

                        <div class="col-lg-3"></div>
                        <div class="col-lg-6 text-center">
                            <p><strong>Habitaciones</strong></p>
                            <p class="text-danger text-size-12">El tamaño recomendado de la imagen debe ser de 600 x 450
                            </p>
                            <p class="text-center">
                                <select class="form-control form-control-sm text-normal" (change)="selectRoomType($event)">
                                    <option value="{{t.id_tiphab}}" *ngFor="let t of typesRooms">{{t.nombre}}</option>
                                </select>
                            </p>
                        </div>
                        <div class="col-lg-3"></div>

                        <div class="col-lg-12 table-responsive" *ngIf="rooms.length > 0">

                            <table class="table table-hover text-size-13">
                                <thead>
                                    <tr class="text-center">
                                        <!-- <th scope="col">id</th> -->
                                        <th scope="col">Número</th>
                                        <th scope="col">Img 1</th>
                                        <th scope="col">Img 2</th>
                                        <th scope="col">Img 3</th>
                                        <th scope="col">Descripción</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of rooms" class="text-size-13 text-normal text-center">
                                        <!-- <th scope="row">{{r.id_hab}}</th> -->
                                        <td>{{r.nro}}</td>
                                        <td>
                                            <!-- <img src="https://drive.google.com/uc?export=view&id={{r.imagen_uno}}"
                                                class="mr-2" style="max-width: 150px" *ngIf="r.imagen_uno">
                                            <img src="https://drive.google.com/uc?export=view&id=1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2"
                                                class="mr-2" style="max-width: 150px" *ngIf="!r.imagen_uno"> -->
                                                <img [src]="urlBase+r.imagen_uno+'?alt=media'"   class="mr-2" style="max-width: 150px; width: 150px;"  *ngIf="r.imagen_uno" alt="">
                                                <img [src]="urlBase+r.imagen_uno+'?alt=media'"  class="mr-2" style="max-width: 150px"   *ngIf="!r.imagen_uno" alt="">
                                            <i class="fas fa-edit text-size-16"
                                                (click)="ModalUploadFile(uploadFileModal, 'Room_1', r)"></i>
                                        </td>
                                        <td>
                                            <!-- <img src="https://drive.google.com/uc?export=view&id={{r.imagen_dos}}"
                                                class="mr-2" style="max-width: 150px" *ngIf="r.imagen_dos">
                                            <img src="https://drive.google.com/uc?export=view&id=1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2"
                                                class="mr-2" style="max-width: 150px" *ngIf="!r.imagen_dos"> -->
                                                <img [src]="urlBase+r.imagen_dos+'?alt=media'"   class="mr-2" style="max-width: 150px"   *ngIf="r.imagen_dos" alt="">
                                                <img [src]="urlBase+r.imagen_dos+'?alt=media'"   class="mr-2" style="max-width: 150px"   *ngIf="!r.imagen_dos" alt="">
                                            <i class="fas fa-edit text-size-16"
                                                (click)="ModalUploadFile(uploadFileModal, 'Room_2', r)"></i>
                                        </td>
                                        <td>
                                            <!-- <img src="https://drive.google.com/uc?export=view&id={{r.imagen_tres}}"
                                                class="mr-2" style="max-width: 150px" *ngIf="r.imagen_tres">
                                            <img src="https://drive.google.com/uc?export=view&id=1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2"
                                                class="mr-2" style="max-width: 150px" *ngIf="!r.imagen_tres"> -->
                                                <img [src]="urlBase+r.imagen_tres+'?alt=media'"  class="mr-2" style="max-width: 150px"   *ngIf="r.imagen_tres" alt="">
                                                <img [src]="urlBase+r.imagen_tres+'?alt=media'"  class="mr-2" style="max-width: 150px"   *ngIf="!r.imagen_tres" alt="">
                                            <i class="fas fa-edit text-size-16"
                                                (click)="ModalUploadFile(uploadFileModal, 'Room_3', r)"></i>
                                        </td>
                                        <td>
                                            <textarea style="font-size: 13px;" class="form-control form-control-sm text-normal" rows="3" [(ngModel)]="r.descripcion"></textarea>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-dark btn-sm" (click)="updateDescriptionRoom(r)">
                                                <i class="far fa-save"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div class="container" *ngIf="menu.credenciales">

                    <div class="row">

                        <div class="col-lg-3"></div>

                        <div class="col-lg-6 text-center">

                            <div class="form-group text-size-14">
                                <label for="nombre">Usuario</label>
                                <input style="font-size: 13px;" class="form-control form-control-sm text-normal"
                                    type="text" [(ngModel)]="updCredentials.user">
                            </div>

                            <div class="form-group text-size-14">
                                <label for="nombre">Contraseña Actual</label>
                                <input style="font-size: 13px;" class="form-control form-control-sm text-normal"
                                    type="password" [(ngModel)]="updCredentials.currentPassword">
                            </div>

                            <div class="form-group text-size-14">
                                <label for="nombre">Contraseña Nueva</label>
                                <input style="font-size: 13px;" class="form-control form-control-sm text-normal"
                                    type="password" [(ngModel)]="updCredentials.newPassword">
                            </div>

                            <button type="button" class="btn btn-dark" (click)="updateCredentials(updCredentials)">
                                <i class="far fa-save"></i>
                            </button>

                        </div>

                        <div class="col-lg-3"></div>

                    </div>


                </div>

            </main>

        </div>
    </div>

</section>

<!-- Administrar imagenes Sobre nosostros-->
<ng-template #uploadFileModal let-modal>

    <div class="row pt-3 tipo-letra text-size-12">
        <div class="col-9 text-center">
            <h6>Seleccione la imagen</h6>
        </div>
        <div class="col-2 float-left">
            <button type="button" class="close text-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>

    <div class="row modal-body tipo-letra text-center">
        <div class="col-lg-10">
            <input style="font-size: 13px;" class="form-control" #files type="file" accept="image/*">
        </div>
        <div class="col-lg-2">
            <button type="button" ngbAutofocus class="btn btn-outline-info ml-1" (click)="modal.close(files)">
                <i class="fas fa-cloud-upload-alt"></i>
            </button>
        </div>
    </div>

</ng-template>

<!-- Alerta para eliminar un archivo -->
<ng-template #deleteFileModal let-modal>

    <div class="row pt-3 tipo-letra text-size-12">
        <div class="col-12 text-center text-size-13">
            <span>Seguro que desea eliminar</span>
        </div>
        <!-- <div class="col-2 float-left">
            <button type="button" class="close text-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div> -->
    </div>

    <div class="row modal-body tipo-letra text-center">

        <div class="col-12 text-center">

            <button type="button" class="btn btn-outline-danger text-center" (click)="modal.dismiss('Cross click')">
                <i class="fas fa-minus-circle"></i>
            </button>

            <button type="button" class="btn btn-outline-success text-center ml-3" (click)="modal.close()">
                <i class="fas fa-check-circle"></i>
            </button>

        </div>

    </div>

</ng-template>
